import React from 'react';
import Modal from 'react-modal';
import Field from '../../../../generic_components/Field'
import TextBox from "./TextBox";
import Qualifications from "./Qualifications";
import JobCreator from "../../../../../backend/jobs/JobCreator";
import LoadingModal from "../../../../generic_components/LoadingModal";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: 500,
        maxHeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class JobAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.submitData = this.submitData.bind(this);
        this.state = {
            isOpen: false,
            name: '',
            wage: '',
            description: '',
            qualifications: '',
            loading: false
        }
    }

    canSubmit() {
        let name = this.state.name;
        let wage = this.state.wage;
        let description = this.state.description;
        let qualifications = this.state.qualifications;
        for (let i = 0; i < qualifications.length; ++i) {
            if (qualifications[i] === '') return false;
        }
        return !(name === '' || wage === '' || description === '' || qualifications.length === 0);
    }

    close() {
        this.setState({
            isOpen: false,
            name: '',
            wage: '',
            description: '',
            qualifications: '',
            loading: false
        });
    }

    open() {
        this.setState({isOpen: true});
    }

    async submitData() {
        if (!this.canSubmit()) return;
        let name = this.state.name;
        let wage = this.state.wage;
        let description = this.state.description;
        let qualifications = this.state.qualifications;
        let companyId = this.props.companyId;
        let shouldExit = false;
        qualifications.forEach(qual => {
            if (qual === '')
                shouldExit = true;
        });
        if (this.state.submitting || shouldExit)
            return;
        this.setState({submitting: true, loading: true});
        await JobCreator(companyId, name, wage, description, qualifications);
        this.props.refresh();
        this.setState({submitting: false, loading: false});
        this.close();
    }

    render() {
        if (this.state.loading)
            return <LoadingModal customStyles={customStyles}/>

        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
                ariaHideApp={false}
            >
                <div style={{position: 'relative', width: '100%'}}>
                    <div style={{
                        alignItems: 'left',
                        justifyContent: 'top',
                        flexDirection: 'column',
                        padding: 5,
                        width: 450,
                        overflowY: 'hide',
                        paddingBottom: 35
                    }}>
                        <h2 style={{fontWeight: 'bold'}}>Create Job</h2>
                        <Field
                            title={'Name'}
                            onChange={name => this.setState({name})}
                        />

                        <Field
                            title={'Wage'}
                            onChange={wage => this.setState({wage})}
                            numeric={true}
                        />

                        <TextBox
                            title={'Description'}
                            onChange={description => this.setState({description})}
                        />

                        <Qualifications
                            onChange={qualifications => this.setState({qualifications})}
                            style={{position: 'relative', width: '100%'}}
                        />

                        <input
                            style={{
                                top: 10,
                                position: 'relative',
                                width: 450,
                                height: 40,
                                backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                                borderWidth: 0,
                                borderRadius: 5,
                                color: '#eee',
                                fontWeight: 'bold',
                                cursor: (this.canSubmit()) ? 'pointer' : 'default'
                            }}
                            type="submit" value="Submit"
                            onClick={this.submitData}/>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default JobAddModal