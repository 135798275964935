import ArbitraryCreator from "../ArbitraryCreator";

const path = '/unassignEmployee';

async function unassignEmployee(userId) {
    let queryParameters = {userId}
    let data = await ArbitraryCreator(path, {}, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default unassignEmployee;
