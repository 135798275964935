import { Hub } from "aws-amplify";

import React from 'react';
import JobLoader from "../../backend/jobs/JobLoader";
import JobApplications from "./JobApplications";

class LocationApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: []
        };
    }

    componentDidMount() {
        JobLoader(this.props.company.id).then(jobs => {
            this.setState({jobs});
        });
    }

    getJobs() {
        let resp = [];
        for (let i = 0; i < this.state.jobs.length; ++i) {
            let job = this.state.jobs[i];
            if (!this.props.location.jobs.includes(job.id)) continue;
            resp.push(
                <JobApplications
                    job={job}
                    location={this.props.location}
                    company={this.props.company}
                    key={i}
                />
            );
        }
        return resp;
    }

    render() {
        return (
            <div style={{padding: 0, margin: 0, textAlign: 'left'}}>
                <h2 style={{color: '#eee', margin: 0, padding: 0, fontSize: 35, marginBottom: 10}}>
                    {this.props.location.name + ', ' + this.props.location.identifier}
                </h2>
                {this.getJobs()}
            </div>
        );
    }
}

export default LocationApplications;
