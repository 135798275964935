import { Hub } from "aws-amplify";

import React from 'react';
import GenericOverview from "../../generic/GenericOverview";
import ConfirmModal from "../../../../ConfirmModal";
import LocationLoader, {invalidateLocationCache} from "../../../../backend/locations/LocationLoader";
import LocationAddModal from "./Modal/LocationAddModal";
import LocationEditModal from "./Modal/LocationEditModal";
import LocationDeletor from "../../../../backend/locations/LocationDeletor";

class LocationWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.getAddModal = this.getAddModal.bind(this);
        this.getEditModal = this.getEditModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.state = {
            selected: undefined
        };
        this.REFRESH_CHANNEL = 'location-data-' + props.id
    }

    refresh() {
        Hub.dispatch(this.REFRESH_CHANNEL, {});
    }

    getAddModal(obj) {
        return (
            <LocationAddModal
                refresh={this.refresh}
                companyId={this.props.companyId}
                ref={modal => obj.modal = modal}
            />
        )
    }

    getConfirmModal(obj) {
        return (
            <ConfirmModal
                ref={modal => obj.confirmModal = modal}
                info={"Are you sure you want to delete this location? This action CANNOT be undone."}
                confirm={() => {}}
            />
        )
    }

    getEditModal(obj) {
        return (
            <LocationEditModal
                ref={modal => obj.editModal = modal}
                location={this.state.selected}
                companyId={this.props.companyId}
                refresh={this.refresh}
            />
        )
    }

    render() {
        return (
            <div style={{borderRight: 1, borderColor: '#666', borderRightStyle: 'solid'}}>
                <h1>Locations</h1>
                <GenericOverview
                    name={'Location'}
                    loader={() => LocationLoader(this.props.companyId)}
                    refreshChannel={this.REFRESH_CHANNEL}
                    invalidateCache={() => invalidateLocationCache(this.props.companyId)}
                    deletor={LocationDeletor}
                    addModal={this.getAddModal}
                    confirmModal={this.getConfirmModal}
                    editModal={this.getEditModal}
                    onPress={(locationId) => {this.setState({selected: locationId})}}
                />
            </div>
        );
    }
}

export default LocationWrapper;
