import React from 'react';
import Option from './Option';
import {getSuperuser} from "../backend/superuser/SuperuserHandler";

class NavBar extends React.Component {
    getOptions() {
        if (getSuperuser()) {
            return (
                <div style={{marginTop: 65}}>
                    <Option icon="building" title="Agencies" link="/"/>
                    <Option icon="briefcase" title="Companies" link="/company" selected={this.props.selected==="companies"}/>
                    <Option icon="users" title="Applications" link="/applications" selected={this.props.selected==="applications"}/>
                    <Option icon="users-cog" title="Employees" link="/employees" selected={this.props.selected==="employees"}/>
                </div>
            )
        } else {
            return (
                <div style={{marginTop: 65}}>
                    <Option icon="briefcase" title="Companies" link="/" selected={this.props.selected==="companies"}/>
                    <Option icon="users" title="Applications" link="/applications" selected={this.props.selected==="applications"}/>
                    <Option icon="users-cog" title="Employees" link="/employees" selected={this.props.selected==="employees"}/>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={{height: '100%', backgroundColor: "#252525", position: "absolute", width: 75, top: 0}}>
                {this.getOptions()}
            </div>
        );
    }
}

export default NavBar;
