import ArbitraryCreator from "../ArbitraryCreator";

const path = '/createCompany';

async function createCompany(name, image) {
    let body = {
        name,
        image
    };
    let data = await ArbitraryCreator(path, body);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createCompany;
