import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getApplications';
const queryParameters = {};
const cacheIdentifier = 'jale-application-data';

async function loadApplications() {
    let data = await ArbitraryLoader(path, queryParameters, cacheIdentifier);
    for (let i = 0; i < data.length; ++i) {
        if (data[i].jobs === undefined)
            data[i].jobs = []
        for (let j = 0; j < data[i].jobs.length; ++j) {
            data[i].jobs[j] = JSON.parse(data[i].jobs[j]);
        }
    }
    return new Promise( (resolve) => {
        resolve(data);
    });
    /*return new Promise( (resolve) => {
        resolve([
            {id: '0', userId: '0', agencyId: '0', firstName: 'Hunter', familyName: 'Harloff', jobs: [{id: '0', companyId: '0'}, {id: '1', companyId: '0'}], locations: ['Mitchell, SD'], email: 'harloff@umich.edu', phone: '+12694912921', address: '8308 Grapevine Circle, Mattawan, MI'}
        ]);
    });*/
}

function invalidateApplicationCache() {
    sessionStorage.setItem(cacheIdentifier, false);
}

export default loadApplications;

export {
    invalidateApplicationCache
}
