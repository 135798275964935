import React from 'react';
import Header from "../Header";
import SuperuserOverviewBody from "./superuser_page/SuperuserOverviewBody";
import CompanyOverviewBody from "./company_page/company_overview/CompanyOverviewBody"
import CompanyInfoBody from "./company_page/company_details/CompanyInfoBody"
import ApplicationOverviewBody from "./application_page/ApplicationOverviewBody"
import EmployeeOverviewBody from "./employee_page/EmployeeOverviewBody"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {getSuperuser} from "../backend/superuser/SuperuserHandler";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: window.location.pathname
        };
    }

    getSwitch() {
        if (getSuperuser()) {

            return (
                <Switch>
                    <Route exact path='/' component={SuperuserOverviewBody}/>
                    <Route exact path='/company' component={CompanyOverviewBody}/>
                    <Route exact path='/companyinfo' component={CompanyInfoBody}/>
                    <Route exact path='/applications' component={ApplicationOverviewBody}/>
                    <Route exact path='/employees' component={EmployeeOverviewBody}/>
                </Switch>
            )
        } else {
            return (
                <Switch>
                    <Route exact path='/' component={CompanyOverviewBody}/>
                    <Route exact path='/companyinfo' component={CompanyInfoBody}/>
                    <Route exact path='/applications' component={ApplicationOverviewBody}/>
                    <Route exact path='/employees' component={EmployeeOverviewBody}/>
                </Switch>
            )
        }
    }

    render() {
        return (
            <Router>
                <div style={{height: '100%', width: "100%", backgroundColor: "#1d1d1d", overflow: 'hidden'}}>
                    <Header/>
                    {this.getSwitch()}
                </div>
            </Router>
        );
    }
}

export default HomePage;
