import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';


const Marker = () => {
    return (
        <div style={{width: 200}}>
            <div
                style={{backgroundColor: '#2c84ee', height: 25, width: 25, borderRadius: 35,
                    border: '2px #000 solid', left: -15, top: -15, position: 'absolute'}}
            />
            <h3 style={{position: 'absolute', marginTop: 20, textAlign: 'left', left: -37, width: '200px'}}>
                Job Location
            </h3>
        </div>
    )
}

class Map extends Component {
    constructor(props) {
        super(props);
        this.handlePress = this.handlePress.bind(this);
        this.state = {
            selected: {
                lat: 39.895427,
                lng: -101.458349
            }
        }
    }

    handlePress(selected) {
        this.setState({selected});
        this.props.onSelect({lat: selected.lat, lng: selected.lng});
    }

    render() {
        return (
            <div style={{ height: '400px', width: '400px' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDnAHu1mkFUVTZCQiJEqieQjyuM9VqoPOk' }}
                    defaultCenter={{
                        lat: 39.895427,
                        lng: -101.458349
                    }}
                    defaultZoom={1}
                    onClick={this.handlePress}
                >
                    <Marker
                        lat={this.state.selected.lat}
                        lng={this.state.selected.lng}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default Map;