import ArbitraryEditor from "../ArbitraryEditor";

const path = '/editLocation';

async function editLocation(companyId, id, jobs) {
    let queryParameters = {companyId, id}
    let body = {
        jobs
    };
    let data = await ArbitraryEditor(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default editLocation;
