import { Auth } from 'aws-amplify';
import { url } from './BackendConstants';
import {getSubuser, getSuperuser} from "./superuser/SuperuserHandler";

async function editData(path, body, queryParameters={}) {
    let data = await patchToWeb(path, body, queryParameters)
        .catch(err => {
            alert("Seems that there was an error. Please refresh your webpage and contact your administrator if problems persist.");
        });

    return new Promise( (resolve) => {
        resolve(data);
    });
}

async function patchToWeb(path, body, queryParameters) {
    let token = await getIdentityToken();
    // Handle superuser
    if (getSuperuser()) {
        if (getSubuser()) {
            queryParameters['overrideAgencyId'] = getSubuser();
        } else {
            window.location.replace("/");
            return;
        }
    }
    // End handle superuser
    let data = await fetch(url + path + '?' +  new URLSearchParams(queryParameters) , {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        'body': JSON.stringify(body)
    }).then(response => response.json());

    return new Promise( (resolve) => {
        resolve(data);
    });
}

async function getIdentityToken() {
    let user = await Auth.currentAuthenticatedUser({bypassCache: false});
    return user.signInUserSession.idToken.jwtToken;
}

export default editData;