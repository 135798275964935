const SUPERUSER_ID = '2b18805d-89de-42da-a5a6-f8b49f6d2aec'
const STORAGE_SUP_ID = 'SUP_USR';
const STORAGE_SUB_ID = 'SUB_USR';

function setSuperuser(agency_id) {
    if (agency_id === SUPERUSER_ID)
        localStorage.setItem(STORAGE_SUP_ID, 'T');
    else
        clearSuperuser();
}

function getSuperuser() {
    return localStorage.getItem(STORAGE_SUP_ID) === 'T';
}

function clearSuperuser() {
    localStorage.removeItem(STORAGE_SUP_ID);
}

function setSubuser(sub_id) {
    localStorage.setItem(STORAGE_SUB_ID, sub_id);
}

function getSubuser() {
    return localStorage.getItem(STORAGE_SUB_ID);
}

function clearSubuser() {
    localStorage.removeItem(STORAGE_SUB_ID);
}

export {
    setSuperuser,
    getSuperuser,
    clearSuperuser,
    setSubuser,
    getSubuser,
    clearSubuser
}
