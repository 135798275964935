import {Hub} from "aws-amplify";

import React from 'react';
import GenericIcon from './GenericIcon'
import GenericAdd from './GenericAdd'

class GenericOverview extends React.Component {
    constructor(props) {
        super(props);
        this.getGeneric = this.getGeneric.bind(this);
        this.populateGeneric = this.populateGeneric.bind(this);
        this.state = {
            data: []
        };
        Hub.listen(props.refreshChannel, async (data) => {
            props.invalidateCache();
            this.populateGeneric();
        });
    }

    componentDidMount() {
        this.populateGeneric();
    }

    async populateGeneric() {
        let data = await this.props.loader();
        this.setState({data});
    }

    onIconPress(generic) {
        this.props.onPress(generic);
        this.editModal.open();
    }

    getGeneric() {
        let array = [];
        let genericData = this.state.data;

        for (let i = 0; i < genericData.length; i++) {
            let generic = genericData[i];
            let name = generic.name;
            let info = generic.identifier;
            array.push(<GenericIcon
                name={name}
                info={info}
                genericData={generic}
                confirmDelete={this.confirmModal}
                refreshChannel={this.props.refreshChannel}
                deletor={this.props.deletor}
                onClick={() => this.onIconPress(generic)}
                editModal={this.editModal}
                key={i}
            />);
        }

        return array;
    }

    render() {
        return (
            <div>
                <div style={{
                    width: '100%',
                    minHeight: 'min-content',
                    justifyContent: 'left',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative'
                }}>

                    {this.getGeneric()}

                    <GenericAdd
                        onClick={()=>{this.modal.open()}}
                        name={this.props.name}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {this.props.addModal(this)}
                    {this.props.confirmModal(this)}
                    {this.props.editModal(this)}
                </div>
            </div>
        )
    }
}

export default GenericOverview