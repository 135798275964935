import { Hub } from "aws-amplify";

import React from 'react';
import User from "./User";
import ApplicationModal from "./Modal/ApplicationModal";
import ApplicationLoader from "../../backend/applications/ApplicationLoader";
import {locationIdToName} from "../../backend/locations/LocationLoader";

class JobApplications extends React.Component {
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this);
        this.shouldPopulatePred = this.shouldPopulatePred.bind(this);
        this.onUserClick = this.onUserClick.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            applications: [],
            selected: undefined,
            userItems: []
        };
    }

    componentDidMount() {
        Hub.listen('refresh-applications', async (data) => {
            this.update();
        });
        this.update();
    }

    update() {
        ApplicationLoader().then(applications => {
            this.setState({applications})
            this.getUsers();
        });
    }

    onUserClick(app) {
        this.setState({selected: app});
        this.appModal.open();
        console.log(app)
    }

    async shouldPopulatePred(app) {
        let foundJob = app.jobs.find(ele => ele.id === this.props.job.id);
        return app.locations.includes(await locationIdToName(this.props.location.id, this.props.company.id)) &&
            foundJob;
    }

    async getUsers() {
        let resp = [];
        for (let i = 0; i < this.state.applications.length; i++) {
            let app = this.state.applications[i];
            if (! await this.shouldPopulatePred(app)) continue;
            resp.push(
                <User
                    firstName={app.firstName}
                    familyName={app.familyName}
                    app={app}
                    onClick={() => this.onUserClick(app)}
                    key={i}
                />
            );
        }
        if (resp.length === 0) {
            resp = <h4 style={{color: '#aaa', marginTop: 0}}>No Applications</h4>
        }
        this.setState({userItems: resp});
    }

    render() {
        return (
            <div style={{padding: 0, margin: 0, textAlign: 'left'}}>

                <h3 style={{color: '#eee', margin: 0, padding: 0}}>
                    {this.props.job.name}
                </h3>
                {this.state.userItems}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ApplicationModal
                        ref={modal => this.appModal = modal}
                        application={this.state.selected}
                        confirm={() => {}}
                    />
                </div>
            </div>
        );
    }
}

export default JobApplications;
