import { Auth } from 'aws-amplify';

async function login(username, password) {
    try {
        console.log("wait")
        const user = await Auth.signIn(username, password);
        console.log(user.challengeName)
        if (user.challengeName === 'SMS_MFA' ||
            user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            // You need to get the code from the UI inputs
            // and then trigger the following function with a button click
            // If MFA is enabled, sign-in should be confirmed with the confirmation code
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // You need to get the new password and required attributes from the UI inputs
            // and then trigger the following function with a button click
            // For example, the email and phone_number are required attributes
            console.log("need password change");
            return {flow: 'change_password', user: user};
        } else if (user.challengeName === 'MFA_SETUP') {
            // This happens when the MFA method is TOTP
            // The user needs to setup the TOTP before using it
            // More info please check the Enabling MFA part
        } else {
            // The user directly signs in
            console.log(user);
            return {flow: 'SUCCESS'};
        }
    } catch (err) {
        console.log(err)
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            return {challenge: "unae"};
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            return {challenge: "unfe"};
        } else {
            return {flow: 'default'};
        }
    }
}

async function changePassword(user, newPassword) {
    if (newPassword.length < 8) {
        return ("Your password needs a minimum of eight characters");
    } else if (newPassword.search(/[a-z]/) < 0) {
        return ("Your password needs a lowercase letter");
    } else if(newPassword.search(/[A-Z]/) < 0) {
        return ("Your password needs an uppercase letter");
    } else  if (newPassword.search(/[0-9]/) < 0) {
        return ("Your password needs a number");
    } else if (newPassword.search(/(?=.*[!@#$%^&*])/)) {
        return ("Your password needs a special character")
    }

    await Auth.completeNewPassword(
        user,               // the Cognito User Object
        newPassword,       // the new password
        {}
    );
    return undefined;
    //login(user.username, newPassword);
}

export {
    login,
    changePassword
}