import AgencyLoader, {invalidateAgencyCache} from "../../backend/agency/AgencyLoader";
import {Hub} from "aws-amplify";

import React from 'react';
import AgencyIcon from './AgencyIcon'

class SuperuserOverview extends React.Component {
    constructor(props) {
        super(props);
        this.getAgencies = this.getAgencies.bind(this);
        this.populateAgencies = this.populateAgencies.bind(this);
        this.state = {
            agencyData: []
        };
        Hub.listen('refresh-companies', async (data) => {
            invalidateAgencyCache();
            this.populateAgencies();
        });
    }

    componentDidMount() {
        this.populateAgencies();
    }

    async populateAgencies() {
        let agencyData = await AgencyLoader();
        this.setState({agencyData});
    }

    getAgencies() {
        let agencyArray = [];
        let agencyData = this.state.agencyData;

        for (let i = 0; i < agencyData.length; i++) {
            let agency = agencyData[i];
            let agencyId = agency.id;
            let name = agency.name;
            agencyArray.push(<AgencyIcon
                name={name}
                agencyId={agencyId}
                key={i}
            />);
        }

        return agencyArray;
    }

    render() {
        return (
            <div>
                <div style={{
                    width: '100%',
                    minHeight: 'min-content',
                    justifyContent: 'left',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative'
                }}>

                    {this.getAgencies()}

                </div>
            </div>
        )
    }
}

export default SuperuserOverview