import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getAgencies';
const queryParameters = {};
const cacheIdentifier = 'jale-agency-data';

async function loadAgencies() {

    let agencyData = await ArbitraryLoader(path, queryParameters, cacheIdentifier);
    return new Promise( (resolve) => {
        resolve(agencyData);
    });
}

function invalidateAgencyCache() {
    sessionStorage.setItem(cacheIdentifier, false);
}

export default loadAgencies;

export {
    invalidateAgencyCache
}
