import { Hub } from "aws-amplify";

import React from 'react';
import NavBar from "../../NavBar/NavBar";
import CompanyApplications from "./CompanyApplications";
import CompanyLoader from "../../backend/companies/CompanyLoader";
import ApplicationLoader from "../../backend/applications/ApplicationLoader";
import EmployeeLoader from "../../backend/employees/EmployeeLoader";

class ApplicationOverviewBody extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            height: window.innerHeight - 65,
            width:  window.innerWidth - 75,
            companies: []
        };
        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidMount() {
        this.update();
    }

    async update() {
        ApplicationLoader();
        CompanyLoader().then(companies => {
            this.setState({companies})
        });
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 65});
        this.setState({width: window.innerWidth - 75})
    }

    getCompanies() {
        let resp = [];
        for (let i = 0; i < this.state.companies.length; ++i) {
            let company = this.state.companies[i];
            resp.push(
                <CompanyApplications
                    company={company}
                    key={i}
                />
            )
        }
        return resp;
    }

    render() {
        return (
            <div>
                <NavBar selected={"applications"}/>
                <div style={{height: 'auto', position: 'absolute', top: 65, left: 75}}>
                    <div style={{
                        overflow: 'hidden',
                        height: this.state.height,
                        width: this.state.width
                    }}>
                        <h1 style={{color: '#eee', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '10%', margin: 0, width: '100%'}}>
                            Applications
                        </h1>
                        <div style={{flexDirection: 'row', display: 'flex', flex: 1, justifyContent: 'flex-start', height: '90%'}}>
                            {this.getCompanies()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplicationOverviewBody;
