import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShiftDeletor from "../../../backend/shifts/ShiftDeletor";
import {invalidateShiftCache} from "../../../backend/shifts/ShiftLoader";

function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}

function getFormattedTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + '' + ampm;
    return strTime;
}

class ShiftItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.getDelete = this.getDelete.bind(this);
        this.state = {
            hovered: false
        };
    }

    async handleDelete() {
        this.props.startLoad();
        await ShiftDeletor(this.props.shift.userId, this.props.shift.id);
        invalidateShiftCache(this.props.shift.userId);
        this.props.endLoad();
    }

    getDelete() {
        if (!this.state.hovered)
            return;
        return (
            <FontAwesomeIcon icon={'trash'} size={'1x'} color={'#cb2929'} style={{marginRight: 5, cursor: 'pointer'}}
                             onClick={this.handleDelete}
            />
        )
    }

    render() {
        return (
            <div style={{
                width: '100%',
                borderBottomStyle: 'solid',
                borderColor: '#000',
                display: 'flex',
                height: 50,
                flexDirection: 'row',
                borderWidth: 1,
                alignItems: 'center',
                marginBottom: 0,
                justifyContent: 'space-between'
            }}
                 onMouseEnter={() => this.setState({hovered: true})}
                 onMouseLeave={() => this.setState({hovered: false})}
            >
                <h4 style={{margin: 0, padding: 0}}>
                    {getFormattedDate(new Date(parseInt(this.props.shift.shiftStart) * 1000))}
                </h4>
                <h4 style={{margin: 0, padding: 0, fontWeight: 'normal', marginRight: 5}}>
                    {getFormattedTime(new Date(parseInt(this.props.shift.shiftStart) * 1000)) + ' - '
                        + getFormattedTime(new Date(parseInt(this.props.shift.shiftEnd) * 1000))}
                </h4>
                {this.getDelete()}
            </div>
        )
    }
}

export default ShiftItem