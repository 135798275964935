import ArbitraryDeletor from "../ArbitraryDeletor";

const path = '/removeCompany';

async function deleteCompany(companyId, code) {
    let queryParameters = {companyId, code}
    let data = await ArbitraryDeletor(path, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default deleteCompany;
