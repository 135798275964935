import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getShifts';
const cacheIdentifier = 'jale-shift-data';

async function loadShifts(userId) {
    let queryParameters = {userId}
    let data = await ArbitraryLoader(path, queryParameters, cacheIdentifier + '-' + userId);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

function invalidateShiftCache(userId) {
    sessionStorage.setItem(cacheIdentifier + '-' + userId, false);
}

function updateShiftCache(userId, data) {
    sessionStorage.setItem(cacheIdentifier + '-' + userId, JSON.stringify(data));
}

export default loadShifts;

export {
    invalidateShiftCache,
    updateShiftCache
}
