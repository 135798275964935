import React from 'react';
import Modal from 'react-modal';
import Job from "./Job";
import JobLoader from "../../../../../backend/jobs/JobLoader";
import LocationEditor from "../../../../../backend/locations/LocationEditor";
import LoadingModal from "../../../../generic_components/LoadingModal";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: 500,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class LocationEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.register = this.register.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.generateAssignedJobs = this.generateAssignedJobs.bind(this);
        this.generateUnassignedJobs = this.generateUnassignedJobs.bind(this);
        this.submitData = this.submitData.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            isOpen: false,
            selected: new Set([]),
            jobData: [],
            location: undefined,
            loading: false
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.update(nextProps.location);

    }

    async update(location) {
        if (!location) return;
        if (location === this.state.location) return;
        this.setState({loading: true})
        let jobs = await JobLoader(this.props.companyId);
        this.setState({jobData: jobs, selected: new Set(location.jobs), location: location, loading: false});
    }

    close() {
        this.setState({
            isOpen: false,
            selected: new Set([]),
            jobData: [],
            location: undefined,
            loading: false
        });
    }

    open() {
        this.setState({isOpen: true});
    }

    register(id) {
        let selected = this.state.selected;
        if (selected.has(id))
            selected.delete(id);
        else
            selected.add(id);
        this.setState({'selected': selected});
    }

    canSubmit() {
        return (this.state.jobData.length > 0);
    }

    generateAssignedJobs() {
        let jobs = [];
        let jobData = this.state.jobData;
        for(let i = 0; i < jobData.length; i++) {
            let job = jobData[i];
            if (!this.state.selected.has(job.id)) continue;
            jobs.push(<Job
                key={i}
                id={job.id}
                register={() => this.register(job.id)}
                name={job['name']}
            />);
        }

        return jobs;
    }

    generateUnassignedJobs() {
        let jobs = [];
        let jobData = this.state.jobData;
        for(let i = 0; i < jobData.length; i++) {
            let job = jobData[i];
            if (this.state.selected.has(job.id)) continue;
            jobs.push(<Job
                key={i}
                id={job.id}
                register={() => this.register(job.id)}
                name={job['name']}
            />);
        }

        return jobs;
    }

    async submitData() {
        this.setState({loading: true});
        await LocationEditor(this.props.companyId, this.props.location.id, Array.from(this.state.selected));
        this.props.refresh();
        this.setState({loading: false});
        this.close();
    }

    render() {
        if (this.state.loading) {
            return <LoadingModal customStyles={customStyles}/>
        }

        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
                ariaHideApp={false}
            >
                <div style={{position: 'relative', width: '100%', minHeight: '400px'}}>
                    <div style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: 0,
                        height: '100%',
                        width: '100%'}}>
                        <h2 style={{fontWeight: 'bold', margin: 0, marginBottom: 10}}>Location Overview</h2>
                        <h4 style={{margin: 0, position: 'relative'}}>Assigned Jobs</h4>
                        <div style={{display: 'flex', position: 'relative', width: '100%', flexDirection: 'row',
                            flexWrap: 'wrap', alignItems: 'center', height: 100}}>
                            {this.generateAssignedJobs()}
                        </div>
                        <h4 style={{position: 'relative', margin: 0}}>Unassigned Jobs</h4>
                        <div style={{display: 'flex', position: 'relative', width: '100%', flexDirection: 'row',
                            flexWrap: 'wrap', alignItems: 'center', height: 100}}>
                            {this.generateUnassignedJobs()}
                        </div>

                        <input
                            style={{
                                top: 10,
                                position: 'relative',
                                width: '100%',
                                height: 40,
                                backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                                borderWidth: 0,
                                borderRadius: 5,
                                marginTop: 5,
                                color: '#eee',
                                fontWeight: 'bold',
                                cursor: (this.canSubmit()) ? 'pointer' : 'default'
                            }}
                            type="submit" value="Submit"
                            onClick={this.submitData}/>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default LocationEditModal