import { Auth } from 'aws-amplify';

function logout() {
    Auth.signOut()
        .then()
        .catch();
}

export default {
    logout
};