import ArbitraryCreator from "../ArbitraryCreator";

const path = '/createEmployee';

async function createEmployee(userId, location, jobId, companyId, wage) {
    let queryParameters = {userId}
    let body = {
        location,
        jobId,
        companyId,
        wage
    };
    let data = await ArbitraryCreator(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createEmployee;
