import ArbitraryLoader from '../ArbitraryLoader';

const query = '/getEmployees';
const body = {};
const cacheIdentifier = 'rize-employee-data';

async function loadEmployees() {
    let jobData = await ArbitraryLoader(query, body, cacheIdentifier);
    return new Promise( (resolve) => {
        resolve(jobData);
    });

    /*
    return new Promise( (resolve) => {
        resolve([
            {id: '0', agencyId: '0', firstName: 'Amilcar', familyName: 'Alvarado', companyId: '1', jobId: '13', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '14.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '1', agencyId: '0', firstName: 'Carlos', familyName: 'Chinchilla', companyId: '1', jobId: '13', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '13.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '2', agencyId: '0', firstName: 'Jose', familyName: 'Gonzalez', companyId: '1', jobId: '12', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.50', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '3', agencyId: '0', firstName: 'Wilfredo', familyName: 'Antunez', companyId: '1', jobId: '10', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.50', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '4', agencyId: '0', firstName: 'Jose', familyName: 'Garcia', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '13.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '5', agencyId: '0', firstName: 'Francisco', familyName: 'Ortiz', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '15.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '6', agencyId: '0', firstName: 'Josue', familyName: 'Ramirez', companyId: '1', jobId: '12', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '7', agencyId: '0', firstName: 'Wilfredo', familyName: 'Dubon', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '8', agencyId: '0', firstName: 'Jorge', familyName: 'Rodriguez', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '9', agencyId: '0', firstName: 'Anderson', familyName: 'Cajbon', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '16.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '10', agencyId: '0', firstName: 'Anibal', familyName: 'Rodriguez', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '11', agencyId: '0', firstName: 'Felipe', familyName: 'Rios', companyId: '1', jobId: '11', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '15.00', address: '8308 Grapevine Circle, Mattawan, MI'},



            {id: '1', userId: '1', agencyId: '0', firstName: 'Jason', familyName: 'Harloff', companyId: '1', jobId: '11', locationId: '0', wage: '12.00', email: 'harloff@hmcmi.com', phone: '+12698737495', address: '8308 Grapevine Circle, Mattawan, MI'},

            {id: '12', agencyId: '0', firstName: 'Luis', familyName: 'Valles', companyId: '2', jobId: '21', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '14.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '13', agencyId: '0', firstName: 'Luis', familyName: 'Vasquez', companyId: '2', jobId: '21', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '14', agencyId: '0', firstName: 'Winder', familyName: 'Hernandez', companyId: '2', jobId: '21', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '15', agencyId: '0', firstName: 'Adelio', familyName: 'Chinchilla', companyId: '2', jobId: '21', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '16', agencyId: '0', firstName: 'Keiner', familyName: 'Chinchilla', companyId: '2', jobId: '21', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '17', agencyId: '0', firstName: 'Edelmir', familyName: 'Cruz', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '18', agencyId: '0', firstName: 'Marcos', familyName: 'Pavon', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '19', agencyId: '0', firstName: 'Hugo', familyName: 'Velasquez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '20', agencyId: '0', firstName: 'William', familyName: 'Lopez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '13.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '21', agencyId: '0', firstName: 'Rogelio', familyName: 'Lopez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '13.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '22', agencyId: '0', firstName: 'Welsin', familyName: 'Valdez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '23', agencyId: '0', firstName: 'Simon', familyName: 'Gonzalez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '24', agencyId: '0', firstName: 'Vidal', familyName: 'Garcia', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '25', agencyId: '0', firstName: 'Mayron', familyName: 'Hernandez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '26', agencyId: '0', firstName: 'Oscar', familyName: 'Suazo', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '14.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '27', agencyId: '0', firstName: 'Nelson', familyName: 'Martinez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '28', agencyId: '0', firstName: 'Salome', familyName: 'Valdez', companyId: '2', jobId: '20', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '29', agencyId: '0', firstName: 'Manuel', familyName: 'Rivera', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '30', agencyId: '0', firstName: 'Carlos', familyName: 'Guzman', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '31', agencyId: '0', firstName: 'Everildo', familyName: 'Gonzalez', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '32', agencyId: '0', firstName: 'Jose', familyName: 'Antunez', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '33', agencyId: '0', firstName: 'Naid', familyName: 'Santos', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
            {id: '34', agencyId: '0', firstName: 'Vildar', familyName: 'Santos', companyId: '2', jobId: '22', locationId: '0',
                email: 'harloff@umich.edu', phone: '+1XXX-XXX-XXXX', wage: '12.00', address: '8308 Grapevine Circle, Mattawan, MI'},
        ]);
    });
     */
}

function invalidateEmployeeCache() {
    sessionStorage.setItem(cacheIdentifier, false);
}

export default loadEmployees;

export {
    invalidateEmployeeCache
}