import {Hub} from "aws-amplify";

import React from 'react';
import { Link } from 'react-router-dom';
import './CompanyIconStyle.css'
import CompanyDeletor from "../../../backend/companies/CompanyDeletor";

class CompanyIcon extends React.Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.state = {
            hovered: false
        }
    }

    getBackground() {
        if(this.state.hovered) {
            return "#565656";
        } else {
            return "#3d3d3d";
        }
    }

    async delete(code) {
        let companyId = this.props.companyData.id;
        await CompanyDeletor(companyId, code);
        Hub.dispatch('refresh-companies', {});
        this.props.confirmDelete.close();
        return true;
    }

    deleteConfirmPopup(){
        this.props.confirmDelete.setConfirm(this.delete);
        this.props.confirmDelete.open();
    }

    getDeleteButton() {
        if(this.state.hovered) {
            return (
            <div style={{position: 'absolute', left: 5, top: 5,
                width: 17.5, height: 17.5, display: 'flex', borderRadius: 10,
                alignItems: 'center', justifyContent: 'center'
            }}
                 className="closeButton"
                 onMouseDown={() => this.deleteConfirmPopup()}
            >
                <div style={{color: "#fff", fontSize: 17, marginTop: -4, fontWeight: 'bold'}}>
                    &times;
                </div>
            </div>
            )
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{
                width: 125,
                height: 125,
                borderRadius: 10,
                backgroundColor: this.getBackground(),
                margin: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 0,
                cursor: 'pointer',
                textDecoration: 'none',
                position: 'relative'
            }}
                 onMouseEnter={() => this.setState({hovered: true})}
                 onMouseLeave={() => this.setState({hovered: false})}
            >
                <Link
                    style={{width: '100%', height: '100%', position: 'absolute'}}
                    to={{ pathname: '/companyinfo', state: { companyData: this.props.companyData}}}
                />
                {this.getDeleteButton()}
                <div style={{marginTop: 0, padding: 5}}>
                    <h4 style={{color: "#efefef", lineHeight: 1}}>
                        {this.props.name}
                    </h4>
                    <h5 style={{marginTop: -15, color: "#b7b7b7", fontWeight: '600'}}>
                        {this.props.info}
                    </h5>
                </div>
            </div>
        )
    }
}

export default CompanyIcon