import CompanyLoader, {invalidateCompanyCache} from "../../../backend/companies/CompanyLoader";
import {Hub} from "aws-amplify";

import React from 'react';
import CompanyIcon from './CompanyIcon'
import CompanyAdd from './CompanyAdd'
import CompanyAddModal from "./Modal/CompanyAddModal";
import ConfirmModal from "../../../ConfirmModal"

class CompanyOverview extends React.Component {
    constructor(props) {
        super(props);
        this.getCompanies = this.getCompanies.bind(this);
        this.populateCompanies = this.populateCompanies.bind(this);
        this.state = {
            companyData: []
        };
        Hub.listen('refresh-companies', async (data) => {
            invalidateCompanyCache();
            this.populateCompanies();
        });
    }

    componentDidMount() {
        this.populateCompanies();
    }

    async populateCompanies() {
        let companyData = await CompanyLoader();
        this.setState({companyData});
    }

    getCompanies() {
        let companyArray = [];
        let companyData = this.state.companyData;

        for (let i = 0; i < companyData.length; i++) {
            let company = companyData[i];
            let name = company.name;
            let info = company.identifier;
            companyArray.push(<CompanyIcon
                name={name}
                info={info}
                jobData={company}
                confirmDelete={this.confirmModal}
                companyData={company}
                key={i}
            />);
        }

        return companyArray;
    }

    render() {
        return (
            <div>
                <div style={{
                    width: '100%',
                    minHeight: 'min-content',
                    justifyContent: 'left',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative'
                }}>

                    {this.getCompanies()}

                    <CompanyAdd
                        onClick={()=>{this.modal.open()}}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CompanyAddModal
                        parent={this}
                        ref={modal => this.modal = modal}
                    />
                    <ConfirmModal
                        ref={modal => this.confirmModal = modal}
                        info={"Are you sure you want to delete this job? This action CANNOT be undone."}
                        confirm={() => {}}
                    />
                </div>
            </div>
        )
    }
}

export default CompanyOverview