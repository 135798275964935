import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope, faHome, faPhone} from '@fortawesome/free-solid-svg-icons'
import {locationIdToName} from "../../../backend/locations/LocationLoader";
import {jobIdToName} from "../../../backend/jobs/JobLoader";
import {companyIdToName} from "../../../backend/companies/CompanyLoader";
import ShiftItem from "./ShiftItem";
import ClockItem from "./ClockItem";
import CreateShiftItem from "./CreateShiftItem";
import ShiftLoader from "../../../backend/shifts/ShiftLoader";
import ClockLoader from "../../../backend/shifts/ClockLoader";
import ReassignView from "./reassignview/ReassignView";
import LoadingModal from "../../generic_components/LoadingModal";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '40vw',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class EmployeeModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.register = this.register.bind(this)
        this.update = this.update.bind(this)
        this.getShiftAdd = this.getShiftAdd.bind(this);
        this.renderShifts = this.renderShifts.bind(this);
        this.renderClocks = this.renderClocks.bind(this);
        this.state = {
            isOpen: false,
            employee: undefined,
            shifts: [],
            clocks: [],
            companyName: '',
            jobName: '',
            locationName: '',
            addShift: false,
            reassign: false,
            loading: false
        }
    }

    componentDidUpdate() {
        this.update()
    }

    update() {
        if (!this.props.employee || this.props.employee === this.state.employee) return;
        locationIdToName(this.props.employee.locationId, this.props.employee.companyId)
            .then(locationName => this.setState({locationName}));
        jobIdToName(this.props.employee.jobId, this.props.employee.companyId)
            .then(jobName => this.setState({jobName}));
        companyIdToName(this.props.employee.companyId)
            .then(companyName => this.setState({companyName}));
        ShiftLoader(this.props.employee.userId)
            .then(shifts => this.setState({shifts}));
        ClockLoader(this.props.employee.userId)
            .then(clocks => this.setState({clocks}));
        this.setState({employee: this.props.employee});
    }

    close() {
        this.setState({isOpen: false});
    }

    open() {
        this.update();
        this.setState({isOpen: true});
    }

    register(id) {
        let selected = this.state.selected;
        if (selected.has(id))
            selected.delete(id);
        else
            selected.add(id);
        this.setState({'selected': selected});
    }

    getShiftAdd() {
        if (!this.state.addShift) return;
        return <CreateShiftItem userId={this.state.employee.userId} submit={() => {
                this.setState({addShift: false});
                ShiftLoader(this.props.employee.userId)
                    .then(shifts => this.setState({shifts}));
            }
        }/>
    }

    renderShifts() {
        let resp = [];
        let shifts = this.state.shifts;
        shifts = shifts.sort((first, second) => { return first.shiftStart < second.shiftStart })
        for (let i = 0; i < shifts.length; ++i) {
            let shift = shifts[i];
            resp.push(
                <ShiftItem
                    shift={shift}
                    startLoad={() => this.setState({loading: true})}
                    endLoad={async () => {
                        shifts = await ShiftLoader(this.props.employee.userId);
                        this.setState({shifts, loading: false});
                    }}
                    key={i}
                />
            )
        }
        return resp;
    }

    renderClocks() {
        let resp = [];
        let clocks = this.state.clocks;
        clocks = clocks.sort((first, second) => { return first.time < second.time })
        for (let i = 0; i < clocks.length; ++i) {
            let clock = clocks[i];
            resp.push(
                <ClockItem
                    type={clock.type}
                    time={new Date(parseInt(clock.time) * 1000)}
                    verified={clock.verified}
                    key={i}
                />
            )
        }
        return resp;
    }

    render() {
        if (this.props.employee === undefined) return <div/>

        if (this.state.loading)
            return <LoadingModal customStyles={customStyles}/>

        if (this.state.reassign)
            return (
                <Modal
                    isOpen={this.state.isOpen}
                    style={customStyles}
                    onRequestClose={this.close}
                    ariaHideApp={false}
                >
                    <ReassignView
                        employee={this.state.employee}
                        exit={() => this.setState({reassign: false})}
                        customStyles={customStyles}
                        close={this.close}
                    />
                </Modal>
            )

        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
                ariaHideApp={false}
            >
                <div style={{width: '100%', height: '100%'}}>
                    <div style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                        height: '90%'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
                            <h1 style={{fontWeight: 'bold', margin: 0}}>
                                {this.props.employee.firstName + ' ' + this.props.employee.lastName}
                            </h1>
                            <h3 style={{margin: 0, color: '#222'}}>{'$' + this.props.employee.wage + ' hourly'}</h3>
                        </div>

                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)',}}>
                            <div>
                                <h2 style={{margin: 0, padding: 0}}>Company</h2>
                                <p style={{marginTop: 5, padding: 0}}>{this.state.companyName}</p>
                            </div>
                            <div>
                                <h2 style={{margin: 0, padding: 0}}>Location</h2>
                                <p style={{marginTop: 5, padding: 0}}>{this.state.locationName}</p>
                            </div>
                            <div>
                                <h2 style={{margin: 0, padding: 0}}>Job</h2>
                                <p style={{marginTop: 5, padding: 0}}>{this.state.jobName}</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.email} </p>
                                </div>

                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 5}}>
                                    <FontAwesomeIcon icon={faPhone} />
                                    <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.phone} </p>
                                </div>

                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 5}}>
                                    <FontAwesomeIcon icon={faHome} />
                                    <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.address} </p>
                                </div>
                            </div>
                            <div className={'reassignButton'} onClick={() => this.setState({reassign: !this.state.reassign})}>
                                <h2 style={{margin: 0}}>
                                    Reassign
                                </h2>
                            </div>
                        </div>


                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 40, height: '60%'}}>
                            <div style={{display: 'flex', flexDirection: 'column', width: '47.5%', marginRight: '5%'}}>
                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
                                    <h2 style={{margin: 0}}>Shifts</h2>
                                    <h2 style={{margin: 0, cursor: 'pointer'}}
                                        onClick={() => this.setState({addShift: !this.state.addShift})}>
                                        {(this.state.addShift) ? '-' : '+'}
                                    </h2>
                                </div>
                                <hr style={{width: '100%', marginBottom: 0}}/>

                                {this.getShiftAdd()}
                                <div style={{overflowY: 'scroll', height: '100%', width: '100%'}}>
                                    {this.renderShifts()}
                                 </div>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', width: '47.5%'}}>
                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
                                    <h2 style={{margin: 0}}>Clock History</h2>
                                </div>
                                <hr style={{width: '100%', marginBottom: 0}}/>

                                <div style={{overflowY: 'scroll', height: '100%', width: '100%'}}>
                                    {this.renderClocks()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
}

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

export default EmployeeModal
