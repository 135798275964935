import ArbitraryCreator from "../ArbitraryCreator";

const path = '/createShift';

async function createShift(userId, shiftStart, shiftEnd) {
    let queryParameters = {userId}
    let body = {
        shiftStart,
        shiftEnd
    };
    let data = await ArbitraryCreator(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createShift;
