import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getClocks';
const cacheIdentifier = 'jale-clock-data';

async function loadClocks(userId) {
    let queryParameters = {userId}
    let data = await ArbitraryLoader(path, queryParameters, cacheIdentifier + '-' + userId);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

function invalidateClockCache(userId) {
    sessionStorage.setItem(cacheIdentifier + '-' + userId, false);
}

export default loadClocks;

export {
    invalidateClockCache
}
