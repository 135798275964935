import React from 'react';
import { changePassword } from '../../backend/login/LoginHandler'
import {Squares} from "react-activity";

class ChangePasswordView extends React.Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.state = {
            password: '',
            verifyPassword: '',
            tipText: 'New password required',
            loading: false
        };
    }

    async onClick() {
        if (this.state.password !== this.state.verifyPassword) {
            this.setState({tipText: 'Passwords must match'});
            return;
        }
        this.setState({loading: true});
        let challenge = await changePassword(this.props.user, this.state.password);
        this.setState({loading: false});
        if (challenge !== undefined) {
            this.setState({tipText: challenge});
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                    lineHeight: 0, position: 'relative', color: "#ddd", width: 200, height: 150, padding: 0, margin: 0}}>
                    <Squares/>
                </div>
            )
        }

        return (
            <div style={{display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column',
                lineHeight: 0, position: 'relative', color: "#ddd"}}>
                <div>
                <h5 style={{top: 7, position: 'relative', color: '#ff605b', fontWeight: '400'}}>
                    {this.state.tipText}
                </h5>
                <h5 style={{top: 10, position: 'relative'}}> New password </h5>
                <input style={{width: 200, borderRadius: 5, borderWidth: 0, color: "#bbb",
                    padding: 5, paddingRight: 10, paddingLeft: 10, backgroundColor: '#555'}} type="password"
                       value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}
                />

                <h5 style={{top: 10, position: 'relative'}}> Confirm new password </h5>
                <input style={{width: 200, borderRadius: 5, borderWidth: 0, color: "#bbb",
                    padding: 5, paddingRight: 10, paddingLeft: 10, backgroundColor: '#555'}} type="password"
                       value={this.state.verifyPassword}
                       onChange={(event) => this.setState({verifyPassword: event.target.value})}
                />
                </div>

                <input className="loginButton" type="submit" value="Submit" style={{cursor: 'pointer'}}
                    onClick={this.onClick}
                />
            </div>
        );
    }
}

export default ChangePasswordView;
