import ArbitraryDeletor from "../ArbitraryDeletor";

const path = '/removeLocation';

async function deleteLocation(locationId, companyId, code) {
    let queryParameters = {locationId, companyId, code}
    let data = await ArbitraryDeletor(path, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default deleteLocation;
