import ArbitraryEditor from "../ArbitraryEditor";

const path = '/editEmployee';

async function editEmployee(userId, location, jobId, companyId, wage) {
    let queryParameters = {userId}
    let body = {
        location,
        jobId,
        companyId,
        wage
    };
    let data = await ArbitraryEditor(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default editEmployee;
