import React, { Component } from 'react';
import './App.css';
import { Auth, Hub } from 'aws-amplify';
import Config from './backend/Config';
import HomePage from "./main/HomePage";
import LoginPage from "./login_page/LoginPage";
import {setSuperuser} from "./backend/superuser/SuperuserHandler";

class App extends Component {
    constructor(props) {
        super(props);
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.state = {
            authenticated: false
        };
        Config();
        Hub.listen('auth', async (data) => {
            this.checkAuthentication();
        });
        this.checkAuthentication();
    }

    checkAuthentication() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setSuperuser(user.attributes.sub);
                this.setState({authenticated: true});
            })
            .catch(err =>  this.setState({authenticated: false}));
    }

    render() {
        if (this.state.authenticated) {
            return (
                <div className="App" style={{height: "100%", width: "100%"}}>
                    <HomePage/>
                </div>
            );
        } else {
            return (
                <LoginPage/>
            )
        }
    }
}

export default App;
