import React from 'react';
import MainLoginView from './main_login/MainLoginView';
import ChangePasswordView from './change_password/ChangePasswordView';
import logo from './logo_opacity.png';
import './login_page.css';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.getView = this.getView.bind(this);
        this.setFlow = this.setFlow.bind(this);
        this.state = {
            flow: 'default',
            user: ''
        };
    }

    setFlow(bundle) {
        let flow = bundle.flow;
        if (bundle.user !== undefined) {
            this.setState({user: bundle.user});
        }
        if (flow === 'SUCCESS') {

            return;
        }
        this.setState({flow: flow});
    }

    getView() {
        if (this.state.flow === 'default') {
            return (
                <MainLoginView
                    onClick={this.setFlow}
                />
            )
        } else if (this.state.flow === 'change_password') {
            return (
                <ChangePasswordView
                    onClick={this.setFlow}
                    user={this.state.user}
                />
            )
        }
    }

    render() {
        return (
            <div style={{height: '100%', width: "100%", backgroundColor: "#1d1d1d", overflow: 'hidden'}}>
                <div style={{height: '100%', width: '100%', display: 'flex',
                    alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 0,
                    backgroundSize: 'cover', backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}>
                    <div style={{width: 'fit-content', height: 'fit-content', backgroundColor: 'rgba(29, 29, 29, 0.9)', padding: 20, paddingBottom: 60, borderRadius: 20}}>
                        {this.getView()}
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
