import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ReassignView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                <FontAwesomeIcon icon={'chevron-left'} size={'2x'}
                                 style={{position: 'absolute', left: 10, top: 10, cursor: 'pointer'}}
                                 onClick={this.props.exit}
                />
                <h2> Are you sure you'd like to {this.props.text} this employee? </h2>

                <input
                    style={{
                        top: 10,
                        position: 'relative',
                        padding: 10,
                        height: 40,
                        backgroundColor: '#54b154',
                        borderWidth: 0,
                        borderRadius: 5,
                        color: '#eee',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}
                    type="submit" value="Yes, I'm sure!"
                    onClick={this.props.confirm}/>
            </div>
        )
    }
}

export default ReassignView
