import ArbitraryDeletor from "../ArbitraryDeletor";

const path = '/removeEmployee';

async function deleteEmployee(userId) {
    let queryParameters = {userId}
    let data = await ArbitraryDeletor(path, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default deleteEmployee;
