import React from 'react';
import { Link } from 'react-router-dom';
import '../company_page/company_overview/CompanyIconStyle.css'
import {setSubuser} from "../../backend/superuser/SuperuserHandler";

class AgencyIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        }
    }

    getBackground() {
        if(this.state.hovered) {
            return "#565656";
        } else {
            return "#3d3d3d";
        }
    }

    render() {
        return (
            <div style={{
                width: 125,
                height: 125,
                borderRadius: 10,
                backgroundColor: this.getBackground(),
                margin: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 0,
                cursor: 'pointer',
                textDecoration: 'none',
                position: 'relative'
            }}
                 onMouseEnter={() => this.setState({hovered: true})}
                 onMouseLeave={() => this.setState({hovered: false})}
                 onClick={() => setSubuser(this.props.agencyId)}
            >
                <Link
                    style={{width: '100%', height: '100%', position: 'absolute'}}
                    to={{ pathname: '/company'}}
                />

                <div style={{marginTop: 0, padding: 5}}>
                    <h4 style={{color: "#efefef", lineHeight: 1}}>
                        {this.props.name}
                    </h4>
                </div>
            </div>
        )
    }
}

export default AgencyIcon