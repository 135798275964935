//import JobDeletor from '../../../backend/jobs/JobDeletor';
import {Hub} from "aws-amplify";

import React from 'react';
import { Link } from 'react-router-dom';
import './CompanyIconStyle.css'

class JobOverview extends React.Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {
            hovered: false,
            deleteHovered: false
        }
    }

    getBackground() {
        if(this.state.hovered) {
            return "#565656";
        } else {
            return "#3d3d3d";
        }
    }

    async delete(code) {
        let genericId = this.props.genericData.id;
        let companyId = this.props.genericData.companyId;
        await this.props.deletor(genericId, companyId, code);
        Hub.dispatch(this.props.refreshChannel, {});
        this.props.confirmDelete.close();
    }

    deleteConfirmPopup(){
        this.props.confirmDelete.setConfirm(this.delete);
        this.props.confirmDelete.open();
    }

    getDeleteButton() {
        if(this.state.hovered) {
            return (
            <div style={{position: 'absolute', left: 5, top: 5,
                width: 17.5, height: 17.5, display: 'flex', borderRadius: 10,
                alignItems: 'center', justifyContent: 'center'
            }}
                 className="closeButton"
                 onMouseEnter={() => this.setState({deleteHovered: true})}
                 onMouseLeave={() => this.setState({deleteHovered: false})}
                 onClick={() => this.deleteConfirmPopup()}
            >
                <div style={{color: "#fff", fontSize: 17, marginTop: -4}}>
                    &times;
                </div>
            </div>
            )
        } else {
            return null;
        }
    }

    getIdentifier() {
        if (this.props.info !== undefined) {
            return (
                <h5 style={{marginTop: -15, color: "#b7b7b7", fontWeight: '600'}}>
                    {this.props.info}
                </h5>
            );
        }
    }

    onClick() {
        if (!this.state.deleteHovered)
            this.props.onClick()
    }

    render() {
        return (
            <div style={{
                width: 125,
                height: 125,
                borderRadius: 10,
                backgroundColor: this.getBackground(),
                margin: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 0,
                cursor: 'pointer',
                textDecoration: 'none',
                position: 'relative'
            }}
                 onMouseEnter={() => this.setState({hovered: true})}
                 onMouseLeave={() => this.setState({hovered: false})}
                 onClick={this.onClick}
            >

                {this.getDeleteButton()}
                <div style={{marginTop: 0, padding: 5}}>
                    <h4 style={{color: "#efefef", lineHeight: 1}}>
                        {this.props.name}
                    </h4>

                    {this.getIdentifier()}

                </div>
            </div>
        )
    }
}

export default JobOverview