import React from 'react';

class Field extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getValue = this.getValue.bind(this);
        this.state = {
            value:  (this.props.default) ? this.props.default : ''
        }
    }

    getValue() {
        return this.state.value;
    }

    onChange(event) {
        let rgx = /^[0-9]*\.?[0-9]*$/;
        if (this.props.numeric) {
            if (event.target.value.match(rgx))
                this.setState({value: event.target.value});
        } else {
            this.setState({value: event.target.value});
        }
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <div>
                <h4 style={{ position: 'relative', margin: 0, marginBottom: 5}}>{this.props.title}</h4>
                <input style={{ position: 'relative'}} type="text" name="title" value={this.state.value}
                       onChange={this.onChange} />
            </div>
        )
    }
}

export default Field