import logo from "./main/logo_opacity.png";
import React from 'react';
import LogoutHandler from './backend/logout/LogoutHandler';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
    return (
        <header style={{
            position: "absolute",
            top: 0,
            height: 65,
            width:"100%",
            backgroundColor: "#2d2d2d",
            display: 'flex',
            alignItems:'center',
            zIndex: 3
        }}>

            <img onClick = {() => {window.location.href = "/"}}
                 src={logo} style={{height: 50, width: 101, margin: 10, cursor: 'pointer'}}/>

            <FontAwesomeIcon
                style={{right: 10, position: 'absolute', cursor: 'pointer'}}
                title="Sign out"
                size="2x" icon={"sign-out-alt"} color={"#888"}
                onClick={LogoutHandler.logout}
            />

        </header>
    );
}

export default Header;
