import React from 'react';
import './buttonstyle.css';
import RadioButton from "./RadioButton";

class ButtonManager extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.getSelected = this.getSelected.bind(this);
        this.getButtons = this.getButtons.bind(this);
        this.getAvailable = this.getAvailable.bind(this);
        this.state = {
            selected: undefined
        }
    }

    getSelected() {
        return this.state.selected;
    }

    onClick(item) {
        if (item === undefined)
            this.setState({selected: undefined});
        else
            this.setState({selected: item.id});
        if (this.props.onChange)
            this.props.onChange(item);
    }

    getAvailable(item) {
        if (this.props.checkLocations) {
            if (this.props.selected)
                return item.locations.includes(this.props.selected.id);
            else
                return false;
        }
        return true
    }

    getButtons() {
        let resp = [];
        let input = this.props.input;
        for (let i = 0; i < input.length; ++i) {
            let item = this.props.input[i];
            if (item.id === this.state.selected) {
                if (!this.getAvailable(item)) {
                    this.onClick(undefined);
                }
            }
            resp.push(
                <RadioButton
                    name={item.name}
                    available={this.getAvailable(item)}
                    onClick={() => this.onClick(item)}
                    selected={this.state.selected === item.id}
                    key={i}
                />
            );
        }
        return resp;
    }

    render() {
        return (
            <div>
                {this.getButtons()}
            </div>
        )
    }
}

export default ButtonManager
