import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}

function getFormattedTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + '' + ampm;
}

class ClockItem extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.renderVerifiedIcon = this.renderVerifiedIcon.bind(this);
    }

    onClick() {
        this.props.onClick();
    }

    renderVerifiedIcon() {
        if (this.props.verified)
            return <FontAwesomeIcon size="1x" icon={'check-circle'} color={'#0fc112'} style={{marginRight: 5}}/>
        return <FontAwesomeIcon size="1x" icon={'times-circle'} color={'#c10f0f'} style={{marginRight: 5}}/>
    }

    render() {
        return (
            <div style={{
                width: '100%',
                borderBottomStyle: 'solid',
                borderColor: '#000',
                display: 'flex',
                height: 50,
                flexDirection: 'row',
                borderWidth: 1,
                alignItems: 'center',
                marginBottom: 0,
                justifyContent: 'space-between'
            }}
            >
                <h4 style={{margin: 0, padding: 0}}>
                    {(this.props.type === 'in') ? 'Clock In' : 'Clock Out'}
                </h4>
                <h4 style={{margin: 0, padding: 0, fontWeight: 'normal'}}>
                    {getFormattedDate(this.props.time)}
                </h4>
                <h4 style={{margin: 0, padding: 0, fontWeight: 'normal'}}>
                    {getFormattedTime(this.props.time)}
                </h4>
                {this.renderVerifiedIcon()}
            </div>
        )
    }
}

export default ClockItem
