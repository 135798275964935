import { Hub } from "aws-amplify";

import React from 'react';
import Modal from 'react-modal';
import ButtonManager from "./ButtonManager";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'
import {getJobLocations, jobIdToName} from "../../../backend/jobs/JobLoader";
import Field from "../../generic_components/Field";
import LoadingModal from "../../generic_components/LoadingModal";
import createEmployee from "../../../backend/employees/EmployeeCreator";
import ApplicationLoader, {invalidateApplicationCache} from "../../../backend/applications/ApplicationLoader";
import EmployeeLoader, {invalidateEmployeeCache} from "../../../backend/employees/EmployeeLoader";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: 500,
        maxHeight: 500,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

function formatLocations(locs) {
    let resp = [];
    for (let i = 0; i < locs.length; i++) {
        resp.push({'name': locs[i], 'id': locs[i]});
    }
    return resp;
}

class ApplicationModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.submit = this.submit.bind(this);
        this.update = this.update.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.state = {
            isOpen: false,
            application: undefined,
            formattedJobs: [],
            selectedLocation: undefined,
            selectedJob: undefined,
            wage: '',
            loading: false
        }
        this.update();
    }

    componentDidUpdate() {
        this.update();
        console.log(this.state.selectedJob)
    }

    async update() {
        if (this.state.application === this.props.application) return;
        let application = this.props.application;
        let formattedJobs = [];
        for (let i = 0; i < application.jobs.length; ++i) {
            let jobPackage = application.jobs[i];
            let jobName = await jobIdToName(jobPackage.id, jobPackage.companyId);
            if (jobName === '') continue;
            let locations = await getJobLocations(jobPackage.id, jobPackage.companyId);
            formattedJobs.push({id: jobPackage.id, companyId: jobPackage.companyId, name: jobName, locations});
        }
        this.setState({formattedJobs, application})
    }

    close() {
        this.setState({isOpen: false, selectedLocation: undefined,
            selectedJob: undefined, application: undefined, wage: ''});
    }

    open() {
        this.setState({isOpen: true});
    }

    async submit() {
        if (this.canSubmit()) {
            this.setState({loading: true});
            let userId = this.state.application.userId;
            let location = this.state.selectedLocation.id;
            let jobId = this.state.selectedJob.id;
            let companyId = this.state.selectedJob.companyId;
            let wage = this.state.wage;
            await createEmployee(userId, location, jobId, companyId, wage);
            invalidateApplicationCache();
            invalidateEmployeeCache();
            await ApplicationLoader();
            await EmployeeLoader();
            Hub.dispatch('refresh-applications', {});
            Hub.dispatch('refresh-employees', {});
            this.setState({loading: false});
            this.close();
        }
    }

    canSubmit() {
        return this.state.selectedLocation !== undefined && this.state.selectedJob !== undefined
            && this.state.wage !== '';
    }

    render() {
        if (this.props.application === undefined) {
            return (
                <div/>
            )
        }

        if (this.state.loading) {
            return (
                <LoadingModal customStyles={customStyles}/>
            )
        }

        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
            >
                <div style={{position: 'relative', width: '100%', minHeight: '430px'}}>
                    <div style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        position: 'absolute',
                        top: 0,
                        padding: 0,
                        margin: 0,
                        paddingBottom: 35}}>
                        <h1 style={{fontWeight: 'bold', marginBottom: 10}}>
                            {this.props.application.firstName + ' ' + this.props.application.lastName}
                        </h1>

                        <h3 style={{margin: 0}}>Locations</h3>
                        <ButtonManager
                            input={formatLocations(this.props.application.locations)}
                            onChange={(selectedLocation) => this.setState({selectedLocation})}
                        />

                        <h3 style={{margin: 0, marginTop: 5}}>Jobs</h3>
                        <ButtonManager
                            input={this.state.formattedJobs}
                            onChange={(selectedJob) => this.setState({selectedJob})}
                            selected={this.state.selectedLocation}
                            checkLocations={true}
                        />

                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10}}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.application.email} </p>
                        </div>

                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 10}}>
                            <FontAwesomeIcon icon={faPhone} />
                            <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.application.phone} </p>
                        </div>

                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%'}}>
                            <FontAwesomeIcon icon={faHome} />
                            <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.application.address} </p>
                        </div>

                        <Field numeric={true} title={'Wage'} onChange={(wage) => this.setState({wage})}/>

                        <input
                            style={{
                                top: 10,
                                position: 'relative',
                                width: 450,
                                height: 40,
                                backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                                borderWidth: 0,
                                borderRadius: 5,
                                color: '#eee',
                                fontWeight: 'bold',
                                cursor: (this.canSubmit()) ? 'pointer' : 'default'
                            }}
                            type="submit" value="Accept Application"
                            onClick={this.submit}/>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ApplicationModal
