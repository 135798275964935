import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getCompanies';
const queryParameters = {};
const cacheIdentifier = 'jale-company-data';

async function loadCompanies() {

    let jobData = await ArbitraryLoader(path, queryParameters, cacheIdentifier);
    console.log(jobData);
    return new Promise( (resolve) => {
        resolve(jobData);
    });
    /*return new Promise( (resolve) => {
        resolve([
            {id: '0', name: 'TCF', identifier: ''},
            {id: '1', name: 'GT Fab', identifier: ''},
            {id: '2', name: 'AZZ Galvanizing', identifier: ''}
        ]);
    });*/
}

async function companyIdToName(companyId) {
    let companies = await loadCompanies();
    let company = companies.find(element => element.id === companyId);
    return company.name;
}

function invalidateCompanyCache() {
    sessionStorage.setItem(cacheIdentifier, false);
}

export default loadCompanies;

export {
    invalidateCompanyCache,
    companyIdToName
}
