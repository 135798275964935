import ArbitraryDeletor from "../ArbitraryDeletor";

const path = '/removeJob';

async function deleteJob(jobId, companyId, code) {
    let queryParameters = {jobId, companyId, code}
    let data = await ArbitraryDeletor(path, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default deleteJob;
