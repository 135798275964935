import { invalidateCompanyCache } from "../../../backend/companies/CompanyLoader";
import CompanyLoader from '../../../backend/companies/CompanyLoader';
import { Hub } from "aws-amplify";

import React from 'react';
import NavBar from "../../../NavBar/NavBar";
import LocationWrapper from "./locations/LocationWrapper";
import JobWrapper from "./jobs/JobWrapper";

class CompanyInfoBody extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            height: window.innerHeight - 65,
            width:  window.innerWidth - 75,
            companyData: this.props.location.state.companyData
        };
        window.addEventListener("resize", this.updateDimensions);
        Hub.listen('refresh-company', async (data) => {
            let companyId = data.payload.data.companyId;
            invalidateCompanyCache();
            let companyData = await CompanyLoader();
            for (let i = 0; i < companyData.length; i++) {
                let datum = companyData[i];
                if (datum.id === companyId) {
                    this.setState({companyData: datum});
                    break;
                }
            }
        });
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 65});
        this.setState({width: window.innerWidth - 75})
    }

    render() {
        return (
            <div>
                <NavBar selected={"companies"}/>
                <div style={{height: 'auto', position: 'absolute', top: 65, left: 75}}>
                    <div style={{
                        overflow: 'hidden',
                        height: this.state.height,
                        width: this.state.width
                    }}>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gridTemplateRows: this.state.height,
                            width: '100%',
                            color: '#eee',
                            fontWeight: 'bold'
                        }}>
                            <LocationWrapper
                                companyId={this.props.location.state.companyData.id}
                            />
                            <JobWrapper
                                companyId={this.props.location.state.companyData.id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyInfoBody;
