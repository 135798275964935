import React from 'react';
import SuperuserOverview from "./SuperuserOverview";

class SuperuserOverviewBody extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            height: window.innerHeight - 85,
            width: window.innerWidth - 95
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidMount() {
        sessionStorage.clear()
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 85});
        this.setState({width: window.innerWidth - 95})
    }

    render() {
        return (
            <div>
                <div style={{height: 'auto', position: 'absolute', top: 65, left: 75, }}>

                    <div style={{
                        padding: 10,
                        flex: 1,
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        height: this.state.height,
                        width: this.state.width
                    }}>
                        <div style={{alignItems: 'center', width: '100%', textAlign: 'center'}}>
                            <h1 style={{color: '#eee'}}>
                                Agencies
                            </h1>
                        </div>
                        <SuperuserOverview/>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuperuserOverviewBody;
