import { Hub } from "aws-amplify";

import React from 'react';
import JobLoader from "../../backend/jobs/JobLoader";
import JobEmployees from "./JobEmployees";
import EmployeeLoader from "../../backend/employees/EmployeeLoader";

class LocationEmployees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            employees: []
        };
    }

    componentDidMount() {
        JobLoader(this.props.company.id).then(jobs => {
            this.setState({jobs});
        });
        EmployeeLoader().then(employees => {
            this.setState({employees})
        });
    }

    getJobs() {
        let resp = [];
        for (let i = 0; i < this.state.jobs.length; ++i) {
            let job = this.state.jobs[i];
            if (!this.props.location.jobs.includes(job.id)) {
                let hiddenEmployee = this.state.employees.find(el => {
                    return (el.locationId === this.props.location.id && el.jobId === job.id);
                });
                if (!hiddenEmployee)
                    continue;
            }
            resp.push(
                <JobEmployees
                    job={job}
                    location={this.props.location}
                    key={i}
                />
            );
        }
        return resp;
    }

    render() {
        return (
            <div style={{padding: 0, margin: 0, textAlign: 'left'}}>
                <h2 style={{color: '#eee', margin: 0, padding: 0, fontSize: 35, marginBottom: 10}}>
                    {this.props.location.name + ', ' + this.props.location.identifier}
                </h2>
                {this.getJobs()}
            </div>
        );
    }
}

export default LocationEmployees;
