import React from 'react';
import './buttonstyle.css';

class RadioButton extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    onClick() {
        if (this.props.available)
            this.props.onClick();
    }

    getClassName() {
        if (!this.props.available)
            return 'buttonGrey';
        return (this.props.selected) ? 'buttonOutlineSelected' : 'buttonOutline';
    }


    render() {
        return (
            <input className={this.getClassName()}
                   type='button'
                   onClick={this.onClick}
                   value={this.props.name}/>
        )
    }
}

export default RadioButton
