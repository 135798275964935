import React from 'react';
import Modal from 'react-modal';
import Field from "../../../generic_components/Field";
import CompanyCreator from "../../../../backend/companies/CompanyCreator";
import {invalidateCompanyCache} from "../../../../backend/companies/CompanyLoader";
import LoadingModal from "../../../generic_components/LoadingModal";

function getBase64(file, cb, err) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result);
    };
    reader.onerror = function (error) {
        err(error);
    };
}

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: 300,
        height: 300,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class CompanyAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.canSubmit = this.canSubmit.bind(this);
        this.submitData = this.submitData.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.fileInput = React.createRef();
        this.state = {
            isOpen: false,
            submitting: false,
            filePath: null,
            name: ''
        }
    }

    canSubmit() {
        let name = this.state.name;
        let file = this.fileInput.current;
        if (file === null)
            return false;
        let fileLength = file.files.length;
        return !(name === '' || fileLength === 0);
    }

    async submitData() {
        let name = this.state.name;
        let fileInput = this.fileInput;
        if (this.state.submitting || !this.canSubmit())
            return;
        this.setState({submitting: true});
        getBase64(fileInput.current.files[0], async file => {
            await CompanyCreator(name, file);
            invalidateCompanyCache();
            await this.props.parent.populateCompanies();
            this.setState({submitting: false});
            this.close();
        }, err => {
            this.setState({submitting: false});
        });
    }

    close() {
        this.setState({
            isOpen: false,
            submitting: false,
            filePath: null,
            name: ''
        });
    }

    open() {
        this.setState({isOpen: true});
    }

    render() {
        if (this.state.submitting)
            return <LoadingModal customStyles={customStyles}/>
        
        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
                ariaHideApp={false}
            >
                <div style={{
                    alignItems: 'left',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    padding: 5,
                    width: 250,
                    height: '100%',
                    overflowY: 'hidden'
                }}>
                    <h2 style={{fontWeight: 'bold', margin: 0, marginBottom: 5}}>Create Company</h2>
                    <Field
                        title={'Name'}
                        onChange={(name) => this.setState({name})}
                        ref={el => this.title = el}
                    />

                    <h4 style={{ position: 'relative', margin: 0, marginBottom: 5, marginTop: 5}}>Company Logo</h4>
                    <input type="file" id="img" name="img" accept="image/jpeg"
                           onChange={(e) => this.setState({filePath: e.target.value})}
                           ref={this.fileInput}
                    />

                    <input
                        style={{
                            top: 10,
                            position: 'relative',
                            width: '100%',
                            height: 40,
                            backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                            borderWidth: 0,
                            borderRadius: 5,
                            marginTop: 5,
                            color: '#eee',
                            fontWeight: 'bold',
                            cursor: (this.canSubmit()) ? 'pointer' : 'default'
                        }}
                        type="submit" value="Submit"
                        onClick={this.submitData}/>
                </div>
            </Modal>
        )
    }
}

export default CompanyAddModal