import React from 'react';

function getInitials(name, loaded) {
    if (loaded) return '';
    let initials = '';
    initials += name[0];
    if (name.indexOf(' ') !== -1)
        initials += name[name.indexOf(' ')+1];
    return initials;
}

class User extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.hovered = this.hovered.bind(this);
        this.unhovered = this.unhovered.bind(this);
        this.getOverlay = this.getOverlay.bind(this);
        this.state = {
            opacity: 0,
            selected: false,
            loaded: false
        };
    }

    toggle() {
        this.props.register();
        //this.setState({selected: !this.state.selected});
        this.hovered(true);
    }

    hovered(override) {
        if(override === true) {
            this.state.selected = !this.state.selected;
        }
        if(this.state.selected) {
            this.setState({backgroundColor: "#3abe3d"});
            this.setState({opacity: 0.5});
        } else {
            this.setState({backgroundColor: "#000"});
            this.setState({opacity: 0.2});
        }
    }

    unhovered() {
        if (this.state.selected) {
            this.setState({backgroundColor: "#4ae64d"});
            this.setState({opacity: 0.5});
        } else {
            this.setState({backgroundColor: "#000"});
            this.setState({opacity: 0});
        }
    }

    getOverlay() {
        return (
            <div
                style={{width: 50,
                    height: 50,
                    borderRadius: 25,
                    position: 'absolute',
                    backgroundColor: this.state.backgroundColor,
                    opacity: this.state.opacity
                }}
                onMouseDown={this.toggle}
                onMouseEnter={this.hovered}
                onMouseLeave={this.unhovered}
            />
        )
    }

    render() {
        return (
            <div style={{margin: 10, display: 'flex', alignItems: 'center',
                justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: '#535353',
                    backgroundSize: '50px 50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    cursor: 'pointer',
                    textDecoration: 'none'
                }}
                >
                    <h2 style={{color: "#ffffff"}}>
                        {getInitials(this.props.name, this.state.loaded)}
                    </h2>
                    {this.getOverlay()}
                </div>
                <h5 style={{color: "#262626", lineHeight: 1, margin: 0, marginTop: 2, position: 'relative', fontWeight: '300'}}>
                    {this.props.name}
                </h5>
            </div>
        )
    }
}

export default User