import ArbitraryLoader from '../ArbitraryLoader';
import loadLocations from "../locations/LocationLoader";

const path = '/getJobs';
const cacheIdentifier = 'jale-job-data';

async function loadJobs(companyId) {
    let queryParameters = {companyId};
    let jobData = await ArbitraryLoader(path, queryParameters, cacheIdentifier + '-' + companyId);
    return new Promise( (resolve) => {
        resolve(jobData);
    });

    /*
    return new Promise( (resolve) => {
    let jobs =
        {
            '0':
                [
                    {id: '0', name: 'General Worker', wage: '15', description: 'This job involves doing work with pipes.', qualifications: ['Knowing what a pipe looks like', 'Experience working with metal']},
                    {id: '1', name: 'Welder', wage: '10', description: 'In this job you will load kettles.', qualifications: ['Fast Loader', 'Likes to look at kettles']},
                    {id: '2', name: 'Shift Supervisor', wage: '10', description: 'In this job you will load kettles.', qualifications: ['Fast Loader', 'Likes to look at kettles']},
                ],
            '1':
                [
                    {id: '10', name: 'Quality Lead', wage: '12', description: '', qualifications: []},
                    {id: '12', name: 'Saw Operator', wage: '10', description: '', qualifications: []},
                    {id: '13', name: 'Table Lead', wage: '10', description: '', qualifications: []},
                    {id: '11', name: 'Table Loader', wage: '12', description: 'Manually handle I-Beam loading into fixtures.', qualifications: ['Hard Worker']},
                ],
            '2':
                [
                    {id: '21', name: 'Forklift Operator', wage: '10', description: '', qualifications: []},
                    {id: '22', name: 'Kettle Loader', wage: '10', description: '', qualifications: []},
                    {id: '20', name: 'General Worker', wage: '15', description: 'This job involves doing General Work.', qualifications: []},
                ],
        }

    let resp = jobs[companyId];
    resp.forEach(job => {
        job.identifier = '$' + job.wage + '/hr';
    });
        resolve(resp);
    });*/
}

function invalidateJobCache(companyId) {
    sessionStorage.setItem(cacheIdentifier + '-' + companyId, false);
}

async function jobIdToName(jobId, companyId) {
    let jobs = await loadJobs(companyId);
    let job = jobs.find(element => element.id === jobId);
    return (job) ? job.name : "";
}

async function getJobLocations(jobId, companyId) {
    let resp = [];
    let locations = await loadLocations(companyId);
    locations.forEach(location => {
        let jobs = location.jobs;
        jobs.forEach(locationJobId => {
            if (jobId === locationJobId) {
                let name = location.city + ', ' + location.state;
                resp.push(name);
            }
        });
    });
    return resp;
}

export default loadJobs;

export {
    invalidateJobCache,
    jobIdToName,
    getJobLocations
}