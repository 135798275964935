import { Auth } from 'aws-amplify';
import { url } from './BackendConstants';
import {getSubuser, getSuperuser} from "./superuser/SuperuserHandler";

async function loadData(path, queryParameters={}, cacheIdentifier) {
    let data;
    let cacheData = checkCache(cacheIdentifier);
    //let cacheData = false;
    if (cacheData !== false) {
        data = cacheData;
    } else {
        data = await fetchFromWeb(path, queryParameters, cacheIdentifier)
            .catch(err => {
                alert("Seems that there was an error. Please refresh your webpage and contact your administrator if problems persist.");
            });
    }

    return new Promise( (resolve) => {
        resolve(data);
    });
}

async function fetchFromWeb(path, queryParameters, cacheIdentifier) {
    let token = await getIdentityToken();
    // Handle superuser
    if (getSuperuser() && path !== '/getAgencies') {
        if (getSubuser()) {
            queryParameters['overrideAgencyId'] = getSubuser();
        } else {
            window.location.replace("/");
            return;
        }
    }
    // End handle superuser
    let data = await fetch(url + path + '?' + new URLSearchParams(queryParameters), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(response => response.json());

    return new Promise( (resolve) => {
        sessionStorage.setItem(cacheIdentifier, JSON.stringify(data));
        resolve(data);
    });
}

function checkCache(cacheIdentifier) {
    let data = sessionStorage.getItem(cacheIdentifier);
    if (data) {
        return JSON.parse(data);
    }
    return false;
}

async function getIdentityToken() {
    let user = await Auth.currentAuthenticatedUser({bypassCache: false});
    return user.signInUserSession.idToken.jwtToken;
}

export default loadData;