import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import fontawesome from '@fortawesome/fontawesome';
import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid';
fontawesome.library.add(faCheckSquare, faCoffee);

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "#252525"
        };
        if(props.selected)
            this.color = "#efefef";
        else
            this.color = "#a4a4a4";
    }

    render() {
        return (
            <Link style={{
                height: 75,
                backgroundColor: this.state.backgroundColor,
                width: "100%",
                marginTop: 0,
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                textDecoration: 'none'
            }}
                  to={this.props.link}
                  onMouseEnter={() => this.setState({backgroundColor: "#353535"})}
                  onMouseLeave={() => this.setState({backgroundColor: "#252525"})}
            >
                <div style={{marginTop:20}}>
                    <FontAwesomeIcon size="1x" icon={this.props.icon} color={this.color}/>
                    <h5 style={{color: "#efefef", marginTop: 5, fontSize: 12, fontWeight: "300"}}>
                        {this.props.title}
                    </h5>

                </div>
            </Link>
        );
    }
}

export default NavBar;
