import { Hub } from "aws-amplify";

import React from 'react';
import GenericOverview from "../../generic/GenericOverview";
import ConfirmModal from "../../../../ConfirmModal";
import JobAddModal from "./Modal/JobAddModal";
import JobEditModal from "./Modal/JobEditModal";
import JobLoader, {invalidateJobCache} from "../../../../backend/jobs/JobLoader";
import JobDeletor from "../../../../backend/jobs/JobDeletor";

class JobWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.refresh = this.refresh.bind(this);
        this.getAddModal = this.getAddModal.bind(this);
        this.getEditModal = this.getEditModal.bind(this);
        this.state = {
            selected: undefined
        }
        this.REFRESH_CHANNEL = 'job-data-' + props.id
    }

    refresh() {
        Hub.dispatch(this.REFRESH_CHANNEL, {});
    }

    getAddModal(obj) {
        return (
            <JobAddModal
                refresh={this.refresh}
                companyId={this.props.companyId}
                ref={modal => obj.modal = modal}
            />
        )
    }

    getConfirmModal(obj) {
        return (
            <ConfirmModal
                ref={modal => obj.confirmModal = modal}
                info={"Are you sure you want to delete this job? This action CANNOT be undone."}
                confirm={() => {}}
            />
        )
    }

    getEditModal(obj) {
        return (
            <JobEditModal
                ref={modal => obj.editModal = modal}
                refresh={this.refresh}
                companyId={this.props.companyId}
                job={this.state.selected}
            />
        )
    }

    render() {
        return (
            <div style={{borderRight: 1, borderColor: '#666', borderRightStyle: 'solid'}}>
                <h1>Jobs</h1>
                <GenericOverview
                    name={'Job'}
                    loader={() => JobLoader(this.props.companyId)}
                    refreshChannel={this.REFRESH_CHANNEL}
                    invalidateCache={() => invalidateJobCache(this.props.companyId)}
                    deletor={JobDeletor}
                    addModal={this.getAddModal}
                    confirmModal={this.getConfirmModal}
                    editModal={this.getEditModal}
                    onPress={(jobId) => {this.setState({'selected': jobId})}}
                />
            </div>
        );
    }
}

export default JobWrapper;
