import React from 'react';
import './user.css'

function getInitials(name, loaded) {
    if (loaded) return '';
    let initials = '';
    initials += name[0];
    if (name.indexOf(' ') !== -1)
        initials += name[name.indexOf(' ')+1];
    return initials;
}

class User extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            opacity: 0,
            loaded: false
        };
    }

    onClick() {
        this.props.onClick();
    }

    render() {
        return (
            <div style={{margin: 10, display: 'flex', alignItems: 'center', padding: 0,
                justifyContent: 'center', flexDirection: 'column', width: 'min-content', height: 'min-content'}}>
                <div className={'userIcon'}
                     onMouseDown={this.onClick}
                >
                    <h2 style={{color: "#ffffff"}}>
                        {getInitials(this.props.app.firstName + ' ' + this.props.app.lastName, this.state.loaded)}
                    </h2>
                </div>
                <h5 style={{color: "#eee", textAlign: 'center', lineHeight: 1, top: 0, position: 'relative', fontWeight: '300', fontSize: 15, margin: 5}}>
                    {this.props.app.firstName + ' ' + this.props.app.lastName}
                </h5>
            </div>
        )
    }
}

export default User