import React from 'react';
import { login } from '../../backend/login/LoginHandler';
import {Squares} from "react-activity";

class MainLoginView extends React.Component {
    constructor() {
        super();
        this.login = this.login.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            username: '',
            password: '',
            challenge: '',
            loading: false
        };
    }

    keyPress(e){
        if(e.keyCode === 13){
            this.login();
        }
    }

    async login() {
        if (this.state.username === '' || this.state.password === '') {
            this.setState({challenge: "unfe"});
            return;
        }
        this.setState({loading: true});
        let response = await login(this.state.username, this.state.password);
        this.setState({loading: false});
        if (response.challenge !== undefined) {
            this.setState({challenge: response.challenge});
            return;
        }
        this.props.onClick(response);
    }

    challengeText() {
        if (this.state.challenge === 'unfe' || this.state.challenge === 'unae') {
            return <h5 style={{color: "#FF605B"}}>Incorrect username or password</h5>;
        }
        return null;
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                    lineHeight: 0, position: 'relative', color: "#ddd", width: 200, height: 150, padding: 0, margin: 0}}>
                    <Squares/>
                </div>
            )
        }

        return (
            <div style={{display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column',
                lineHeight: 0, position: 'relative', color: "#ddd"}}>
                <div style={{lineHeight: 0, height: 10}}>
                    {this.challengeText()}
                </div>
                <h5 style={{top: 10, position: 'relative'}}> Username </h5>
                <input style={{width: 200, borderRadius: 5, borderWidth: 0, color: "#bbb",
                    padding: 5, paddingRight: 10, paddingLeft: 10, backgroundColor: '#555'}} type="text"
                    value={this.state.username} onChange={(event) => this.setState({username: event.target.value})}
                />

                <h5 style={{top: 10, position: 'relative'}}> Password </h5>
                <input style={{width: 200, borderRadius: 5, borderWidth: 0, color: "#bbb",
                    padding: 5, paddingRight: 10, paddingLeft: 10, backgroundColor: '#555'}} type="password"
                       value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}
                       onKeyDown={this.keyPress}
                />

                <input className="loginButton" type="submit" value="Submit" style={{cursor: 'pointer'}}
                    onClick={this.login}
                />
            </div>
        );
    }
}

export default MainLoginView;