import { Hub } from "aws-amplify";

import React from 'react';
import User from "./User";
import EmployeeLoader from "../../backend/employees/EmployeeLoader";
import EmployeeModal from "./modal/EmployeeModal";

class JobEmployees extends React.Component {
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            employees: [],
            selected: undefined
        };
    }

    componentDidMount() {
        Hub.listen('refresh-employees', async (data) => {
            this.update();
        });
        this.update();
    }

    update() {
        EmployeeLoader().then(employees => {
            this.setState({employees})
        });
    }

    getUsers() {
        let resp = [];
        for (let i = 0; i < this.state.employees.length; i++) {
            let employee = this.state.employees[i];
            if (employee.locationId !== this.props.location.id || employee.jobId !== this.props.job.id) continue;
            resp.push(
                <User
                    employee={employee}
                    onClick={() => {
                        this.setState({selected: employee});
                        this.employeeModal.open();
                    }}
                    key={i}
                />
            );
        }
        if (resp.length === 0) {
            return <h4 style={{color: '#aaa', marginTop: 0}}>No Assignees</h4>
        }
        return resp;
    }

    render() {
        return (
            <div style={{padding: 0, margin: 0, textAlign: 'left'}}>

                <h3 style={{color: '#eee', margin: 0, padding: 0}}>
                    {this.props.job.name}
                </h3>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {this.getUsers()}
                </div>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <EmployeeModal
                        ref={modal => this.employeeModal = modal}
                        employee={this.state.selected}
                        confirm={() => {}}
                    />
                </div>
            </div>
        );
    }
}

export default JobEmployees;
