import React from 'react';
import LocationLoader from "../../backend/locations/LocationLoader";
import LocationEmployees from "./LocationEmployees";

class CompanyEmployees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: []
        };
    }

    componentDidMount() {
        console.log()
        LocationLoader(this.props.company.id).then(locations => {
            this.setState({locations});
        });
    }

    getLocations() {
        let resp = [];
        for (let i = 0; i < this.state.locations.length; ++i) {
            let location = this.state.locations[i];
            console.log(location);
            resp.push(
                <LocationEmployees
                    location={location}
                    company={this.props.company}
                    key={i}
                />
            );
        }
        return resp;
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', padding: 20, flexDirection: 'column', flex: 1,
                alignItems: 'flex-start', justifyContent: 'flex-start', overflowY: 'scroll',}}>
                    <h1 style={{color: '#fff', margin: 0, padding: 0, fontSize: 50}}>
                        {this.props.company.name}
                        <hr style={{margin: 0, padding: 0, marginBottom: 10}}/>
                    </h1>
                {this.getLocations()}
            </div>
        );
    }
}

export default CompanyEmployees;
