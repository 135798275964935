import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CompanyAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: '#3d3d3d'
        }
    }

    render() {
        return (
            <div style={{
                width: 125,
                height: 125,
                borderRadius: 10,
                backgroundColor: this.state.backgroundColor,
                margin: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 0,
                cursor: 'pointer',
                textDecoration: 'none'
            }}
                 onMouseDown={this.props.onClick}
                 onMouseEnter={() => this.setState({backgroundColor: "#565656"})}
                 onMouseLeave={() => this.setState({backgroundColor: "#3d3d3d"})}
            >
                <div style={{marginTop: 0, padding: 5}}>
                    <FontAwesomeIcon size="4x" icon="plus" color={"#777777"}/>
                    <div style={{width:"100%", position: 'relative',}}>
                        <h5 style={{marginTop: 0, color: "#b7b7b7", fontWeight: '600', position: 'absolute', left: 14}}>
                            New Company
                        </h5>
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyAdd