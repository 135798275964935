import ArbitraryEditor from "../ArbitraryEditor";

const path = '/editJob';

async function createJob(jobId, companyId, name, wage, description, qualifications) {
    let queryParameters = {jobId, companyId}
    let body = {
        name,
        wage,
        description,
        qualifications
    };
    console.log(body);
    let data = await ArbitraryEditor(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createJob;
