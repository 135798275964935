import React from 'react';
import Modal from "react-modal";
import {Squares} from "react-activity";

class LoadingModal extends React.Component {
    render() {
        return (
            <Modal
                isOpen={true}
                style={this.props.customStyles}
                ariaHideApp={false}
            >
                <div style={{position: 'relative', width: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Squares/>
                </div>
            </Modal>
        )
    }
}

export default LoadingModal
