import React from 'react';
import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import Field from "./main/generic_components/Field";
import LoadingModal from "./main/generic_components/LoadingModal";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '300px',
        minHeight: '200px',
        height: 'auto',
        alignItems: 'left',
        justifyContent: 'left',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 20
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

const customStylesLoading = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '300px',
        minHeight: '200px',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 20
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class JobAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.setConfirm = this.setConfirm.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.state = {
            isOpen: false,
            confirm: this.props.confirm,
            loading: false,
            code: '',
            flag: false
        }
    }

    setConfirm(confirm) {
        this.setState({confirm})
    }

    close() {
        this.setState({isOpen: false});
        this.setState({loading: false});
    }

    open() {
        this.setState({isOpen: true});
    }

    async handleConfirm() {
        this.setState({loading: true});
        this.state.confirm(this.state.code)
            .then(data => {
                this.setState({loading: false});
            })
            .catch(err => {
                this.setState({loading: false, flag: true});
            });
    }

    renderFlag(flag) {
        if (flag)
            return <div style={{color: '#bd0d0d', fontSize: 12}}>Incorrect Code</div>
    }

    render() {
        if (this.state.loading)
            return <LoadingModal customStyles={customStylesLoading}/>
        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
                ariaHideApp={false}
            >
                <h3 style={{margin: 0, marginBottom: 20}}>{this.props.info}</h3>
                {this.renderFlag(this.state.flag)}
                <Field title={"Code"} onChange={(code) => this.setState({code})}/>
                <Button variant="danger" style={{position: 'absolute', bottom: 20, right: 20}}
                        onClick={this.handleConfirm}>
                    Accept
                </Button>
                <Button variant="success" style={{position: 'absolute', bottom: 20, left: 20}}
                        onClick={this.close}>
                    Cancel
                </Button>
            </Modal>
        )
    }
}

export default JobAddModal