import React from 'react';
import Modal from 'react-modal';
import Field from '../../../../generic_components/Field'
import StateSelect from "./StateSelect";
import LocationCreator from "../../../../../backend/locations/LocationCreator";
import LoadingModal from "../../../../generic_components/LoadingModal";
import Map from "./Map";

const customStyles = {
    content : {
        top                   : '55%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '470px',
        maxHeight: '80%',
        minHeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
};

class LocationAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.canSubmit = this.canSubmit.bind(this);
        this.submitData = this.submitData.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.state = {
            isOpen: false,
            submitting: false,
            loading: false,
            latlng: undefined,
            city: '',
            stateString: ''
        }
    }

    canSubmit() {
        let {city, stateString, latlng} = this.state;
        return !(city === '' || stateString === '' || latlng === undefined);
    }

    async submitData() {
        let {city, stateString, latlng} = this.state;
        if (this.state.submitting || !this.canSubmit())
            return;
        this.setState({submitting: true, loading: true});
        await LocationCreator(this.props.companyId, stateString, city, latlng.lat, latlng.lng);
        this.props.refresh();
        this.setState({submitting: false, loading: false});
        this.close();
    }

    close() {
        this.setState({
            isOpen: false,
            submitting: false,
            loading: false,
            latlng: undefined,
            city: '',
            stateString: ''
        });
    }

    open() {
        this.setState({isOpen: true});
    }


    render() {
        if (this.state.loading)
            return <LoadingModal customStyles={customStyles}/>

        return (
            <Modal
                isOpen={this.state.isOpen}
                style={customStyles}
                onRequestClose={this.close}
            >
                <div style={{
                    alignItems: 'left',
                    justifyContent: 'top',
                    flexDirection: 'column',
                    padding: 5,
                    width: '90%',
                    overflowY: 'hide'
                }}>
                    <h2 style={{fontWeight: 'bold', margin: 0, marginBottom: 5}}>Create Location</h2>
                    <StateSelect
                        onChange={stateString => this.setState({stateString})}
                    />

                    <Field
                        title={'City'}
                        onChange={city => this.setState({city})}
                    />

                    <h4 style={{ position: 'relative', margin: 0, marginTop: 10, marginBottom: 5}}>
                        Select the job's location (used for geotracking)
                    </h4>

                    <Map onSelect={latlng => this.setState({latlng})}/>

                    <input
                        style={{
                            top: 10,
                            position: 'relative',
                            width: '100%',
                            height: 40,
                            backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                            borderWidth: 0,
                            borderRadius: 5,
                            marginTop: 5,
                            color: '#eee',
                            fontWeight: 'bold',
                            cursor: (this.canSubmit()) ? 'pointer' : 'default'
                        }}
                        type="submit" value="Submit"
                        onClick={this.submitData}/>
                </div>
            </Modal>
        )
    }
}

export default LocationAddModal