import React from 'react';
import DateTimePicker from "react-datetime-picker";
import './buttonstyle.css'
import {Squares} from "react-activity";
import ShiftCreator from "../../../backend/shifts/ShiftCreator";
import ShiftLoader, {updateShiftCache} from "../../../backend/shifts/ShiftLoader";

class CreateShiftItem extends React.Component {
    constructor(props) {
        super(props);
        this.onEndChange = this.onEndChange.bind(this);
        this.onStartChange = this.onStartChange.bind(this);
        this.checkValid = this.checkValid.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            start: '',
            end: '',
            isValid: false,
            loading: false
        }
    }

    onStartChange(start) {
        this.setState({start});
        this.checkValid(start, this.state.end);
    }

    onEndChange(end) {
        this.setState({end});
        this.checkValid(this.state.start, end);
    }

    checkValid(start, end) {
        if (start === '' || end === '')
            this.setState({isValid: false});
        else
            this.setState({isValid: start < end});
    }

    async submit() {
        if (!this.state.isValid) return;
        this.setState({loading: true});
        let start = Math.round(this.state.start.getTime()/1000);
        let end = Math.round(this.state.end.getTime()/1000);
        let newShift = await ShiftCreator(this.props.userId, start, end);
        let shifts = await ShiftLoader(this.props.userId);
        console.log(newShift);
        shifts.push(newShift);
        updateShiftCache(this.props.userId, shifts);
        this.setState({loading: false});
        this.props.submit();
    }

    render() {
        if (this.state.loading)
            return <Squares/>

        return (
            <div style={{
                width: '100%',
                borderBottomStyle: 'solid',
                borderColor: '#000',
                display: 'flex',
                height: 'auto',
                paddingRight: 0,
                flexDirection: 'column',
                borderWidth: 1,
                alignItems: 'flex-start',
                marginBottom: 0,
                justifyContent: 'center',
                paddingBottom: 10,
            }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <h4 style={{marginRight: 10}}>Shift Start</h4>
                    <DateTimePicker
                        onChange={this.onStartChange}
                        value={this.state.start}
                        disableClock={true}
                        minDate={new Date()}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <h4 style={{marginRight: 10}}>Shift End</h4>
                    <DateTimePicker
                        onChange={this.onEndChange}
                        value={this.state.end}
                        disableClock={true}
                        minDate={new Date()}
                    />
                </div>
                <input type={'submit'} className={(this.state.isValid) ? 'confirmButton' : 'confirmButtonInvalid'} value={'CREATE SHIFT'} onClick={this.submit}/>
            </div>
        )
    }
}

export default CreateShiftItem