import { Hub } from "aws-amplify";

import EmployeeDeletor from "../../../../backend/employees/EmployeeDeletor";
import EmployeeUnassigner from "../../../../backend/employees/EmployeeUnassigner";
import EmployeeEditor from "../../../../backend/employees/EmployeeEditor";
import EmployeeLoader, {invalidateEmployeeCache} from "../../../../backend/employees/EmployeeLoader";
import {getJobLocations, jobIdToName} from "../../../../backend/jobs/JobLoader";
import ApplicationLoader, {invalidateApplicationCache} from "../../../../backend/applications/ApplicationLoader";

import React from 'react';
import ButtonManager from "./ButtonManager";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'
import Field from "../../../generic_components/Field";
import LoadingModal from "../../../generic_components/LoadingModal";
import ConfirmationView from "./ConfirmationView";

function formatLocations(locs) {
    let resp = [];
    for (let i = 0; i < locs.length; i++) {
        resp.push({'name': locs[i], 'id': locs[i]});
    }
    return resp;
}

class ReassignView extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.update = this.update.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.submitFire = this.submitFire.bind(this);
        this.submitUnassign = this.submitUnassign.bind(this);
        this.state = {
            isOpen: false,
            application: undefined,
            formattedJobs: [],
            selectedLocation: undefined,
            selectedJob: undefined,
            wage: '',
            loading: false,
            fireConfirmation: false,
            unassignConfirmation: false
        }
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    async update() {
        if (this.state.employee === this.props.employee) return;
        let employee = this.props.employee;
        let formattedJobs = [];
        for (let i = 0; i < employee.jobs.length; ++i) {
            let jobPackage = JSON.parse(employee.jobs[i]);
            let jobName = await jobIdToName(jobPackage.id, jobPackage.companyId);
            if (jobName === '') continue;
            let locations = await getJobLocations(jobPackage.id, jobPackage.companyId);
            formattedJobs.push({id: jobPackage.id, companyId: jobPackage.companyId, name: jobName, locations});
        }
        this.setState({formattedJobs, employee})
    }

    async submit() {
        if (this.canSubmit()) {
            this.setState({loading: true});
            let userId = this.state.employee.userId;
            let location = this.state.selectedLocation.id;
            let jobId = this.state.selectedJob.id;
            let companyId = this.state.selectedJob.companyId;
            let wage = this.state.wage;
            await EmployeeEditor(userId, location, jobId, companyId, wage);
            invalidateEmployeeCache();
            await EmployeeLoader();
            Hub.dispatch('refresh-employees', {});
            this.setState({loading: false});
            this.props.close();
            this.props.exit();
        }
    }

    canSubmit() {
        return this.state.selectedLocation !== undefined && this.state.selectedJob !== undefined
            && this.state.wage !== '';
    }

    async submitFire() {
        this.setState({loading: true, fireConfirmation: true});
        await EmployeeDeletor(this.state.employee.userId)
        invalidateEmployeeCache();
        await EmployeeLoader();
        Hub.dispatch('refresh-employees', {});
        this.setState({loading: false});
        this.props.close();
        this.props.exit();
    }

    async submitUnassign() {
        this.setState({loading: true, fireConfirmation: true});
        await EmployeeUnassigner(this.state.employee.userId)
        invalidateEmployeeCache();
        invalidateApplicationCache();
        await EmployeeLoader();
        await ApplicationLoader();
        Hub.dispatch('refresh-employees', {});
        Hub.dispatch('refresh-applications', {});
        this.setState({loading: false});
        this.props.close();
        this.props.exit();
    }

    render() {
        if (this.props.employee === undefined) {
            return (
                <div/>
            )
        }

        if (this.state.loading)
            return <LoadingModal customStyles={this.props.customStyles}/>

        if (this.state.unassignConfirmation)
            return (
                <ConfirmationView
                    exit={() => this.setState({unassignConfirmation: false})}
                    confirm={this.submitUnassign}
                    text={' unassign '}
                />
            )

        if (this.state.fireConfirmation)
            return (
                <ConfirmationView
                    exit={() => this.setState({fireConfirmation: false})}
                    confirm={this.submitFire}
                    text={' fire '}
                />
            )

        return (
            <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                <FontAwesomeIcon icon={'chevron-left'} size={'2x'}
                                 style={{position: 'absolute', left: 10, top: 10, cursor: 'pointer'}}
                                 onClick={this.props.exit}
                />

                <div style={{display: 'flex', flexDirection: 'column',position: 'absolute', right: 10, top: 10 }}>
                    <div className={'tooltip'}>
                        <FontAwesomeIcon icon={'history'} size={'2x'} color={'#298acb'}
                                         onClick={() => this.setState({unassignConfirmation: true})}
                        />
                        <span className={'tooltiptext'}> UNASSIGN </span>
                    </div>
                    <div className={'tooltip'} style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={'trash'} size={'2x'} color={'#cb2929'}
                                         onClick={() => this.setState({fireConfirmation: true})}
                        />
                        <span className={'tooltiptext'}> FIRE </span>
                    </div>
                </div>

                <div style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    margin: 0}}>
                    <h1 style={{fontWeight: 'bold', marginBottom: 10}}>
                        {this.props.employee.firstName + ' ' + this.props.employee.lastName}
                    </h1>

                    <h3 style={{margin: 0}}>Locations</h3>
                    <ButtonManager
                        input={formatLocations(this.props.employee.locations)}
                        onChange={(selectedLocation) => this.setState({selectedLocation})}
                    />

                    <h3 style={{margin: 0, marginTop: 5}}>Jobs</h3>
                    <ButtonManager
                        input={this.state.formattedJobs}
                        onChange={(selectedJob) => this.setState({selectedJob})}
                        selected={this.state.selectedLocation}
                        checkLocations={true}
                    />

                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10}}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.email} </p>
                    </div>

                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <FontAwesomeIcon icon={faPhone} />
                        <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.phone} </p>
                    </div>

                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%'}}>
                        <FontAwesomeIcon icon={faHome} />
                        <p style={{width: '100%', margin: 0, marginLeft: 10}}> {this.props.employee.address} </p>
                    </div>

                    <Field numeric={true} title={'Wage'} onChange={(wage) => this.setState({wage})}/>

                    <input
                        style={{
                            top: 10,
                            position: 'relative',
                            width: '450',
                            height: 40,
                            backgroundColor: (this.canSubmit()) ? '#54b154' : '#333',
                            borderWidth: 0,
                            borderRadius: 5,
                            color: '#eee',
                            fontWeight: 'bold',
                            cursor: (this.canSubmit()) ? 'pointer' : 'default'
                        }}
                        type="submit" value="Reassign Employee"
                        onClick={this.submit}/>
                </div>
            </div>
        )
    }
}

export default ReassignView
