import React from 'react';

class Qualifications extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.removeField = this.removeField.bind(this);
        this.addField = this.addField.bind(this);
        this.generateFields = this.generateFields.bind(this);
        this.state = {
            values: (this.props.default) ? this.props.default : ['']
        }
    }

    getValues() {
        return this.state.values;
    }

    handleFieldChange(i, input) {
        let tmp = this.state.values;
        tmp[i] = input.target.value;
        this.setState({values: tmp});
        this.props.onChange(tmp);
    }

    removeField(i) {
        let tmp = this.state.values;
        tmp.splice(i,1);
        this.setState({values: tmp});
        this.props.onChange(tmp);
    }

    addField() {
        let tmp = this.state.values;
        if(tmp.length > 9) return;
        tmp.push("");
        this.setState({values: tmp});
        this.props.onChange(tmp);
    }

    generateFields() {
        let result = [];
        for(let i = 1; i < this.state.values.length; i++) {
            result.push(
                <div key={i} style={{width: '100%', position: 'relative',
                    display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input
                        style={{position: 'relative', width: '85%', marginTop: 5}}
                        type="text" name="name" value={this.state.values[i]}
                        onChange={(input) => this.handleFieldChange(i, input)}
                    />
                    <div onMouseDown={()=>{this.removeField(i)}}
                         style={{position: 'absolute', right: '10%', color: "#888", cursor: 'pointer'}}>
                        x
                    </div>
                </div>
            );
        }
        return result;
    }

    render() {
        return (
            <div style={this.props.style}>
                <h4 style={{marginTop: 10, marginBottom: 5}}>Qualifications</h4>
                <input style={{position: 'relative', width: '95%'}} type="text" name="name"
                       value={this.state.values[0]} onChange={(input) => this.handleFieldChange(0, input)}/>
                {this.generateFields()}
                <h6 style={{position: 'relative', cursor: 'pointer', marginTop: 5, marginBottom: 0}}
                    onMouseDown={this.addField}
                >+ qualification</h6>
            </div>
        )
    }
}

export default Qualifications