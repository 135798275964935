import ArbitraryLoader from '../ArbitraryLoader';

const path = '/getLocations';
const cacheIdentifier = 'jale-location-data';

async function loadLocations(companyId) {
    let queryParameters = {companyId};
    let jobData = await ArbitraryLoader(path, queryParameters, cacheIdentifier + '-' + companyId);
    return new Promise( (resolve) => {
        jobData.forEach(location => {
            location.name = location.city;
            location.identifier = location.state
        });
        resolve(jobData);
    });

    /*
    return new Promise( (resolve) => {
        let locations = {
            '0':
                [
                    {id: '0', city: 'Mitchell', state: 'SD', jobs: ['0', '1']},
                    {id: '0', city: 'Elkton', state: 'SD', jobs: ['0', '1']}
                ],
            '1':
                [
                    {id: '0', city: 'Port Allen', state: 'LA', jobs: ['10', '11', '12', '13']}
                ],
            '2':
                [
                    {id: '0', city: 'Port Allen', state: 'LA', jobs: ['20', '21', '22']},
                ],
        };
        let resp = locations[companyId];

        resp.forEach(location => {
            location.name = location.city;
            location.identifier = location.state
        });
        resolve(resp);
    });*/
}

function invalidateLocationCache(companyId) {
    sessionStorage.setItem(cacheIdentifier + '-' + companyId, false);
}

async function locationIdToName(locationId, companyId) {
    let locations = await loadLocations(companyId);
    let location = locations.find(element => element.id === locationId);
    return location.city + ', ' + location.state;
}

export default loadLocations;

export {
    invalidateLocationCache,
    locationIdToName
}
