let url;
if (process.env.NODE_ENV === 'production') {
    url = 'https://0z8xekvjic.execute-api.us-east-2.amazonaws.com/prod/api';
} else {
    url =  'https://0z8xekvjic.execute-api.us-east-2.amazonaws.com/beta/api';
}

module.exports = {
    url
};
