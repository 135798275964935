import ArbitraryCreator from "../ArbitraryCreator";

const path = '/createJob';

async function createJob(companyId, name, wage, description, qualifications) {
    let queryParameters = {companyId}
    let body = {
        name,
        wage,
        description,
        qualifications
    };
    let data = await ArbitraryCreator(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createJob;
