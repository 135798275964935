import ArbitraryDeletor from "../ArbitraryDeletor";

const path = '/removeShift';

async function deleteShift(userId, shiftId) {
    let queryParameters = {userId, shiftId}
    let data = await ArbitraryDeletor(path, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default deleteShift;
