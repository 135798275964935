import ArbitraryCreator from "../ArbitraryCreator";

const path = '/createLocation';

async function createLocation(companyId, state, city, lat, lng) {
    let queryParameters = {companyId}
    let body = {
        state,
        city,
        lat,
        lng
    };
    let data = await ArbitraryCreator(path, body, queryParameters);
    return new Promise( (resolve) => {
        resolve(data);
    });
}

export default createLocation;
