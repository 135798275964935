import { Hub } from "aws-amplify";

import React from 'react';
import NavBar from "../../NavBar/NavBar";
import CompanyEmployees from "./CompanyEmployees";
import CompanyLoader from "../../backend/companies/CompanyLoader";
import EmployeeLoader from "../../backend/employees/EmployeeLoader";


class EmployeeOverviewBody extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            height: window.innerHeight - 65,
            width:  window.innerWidth - 75,
            companies: []
        };
        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidMount() {
        this.update();
    }

    async update() {
        await EmployeeLoader();
        CompanyLoader().then(companies => {
            this.setState({companies})
        });
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 65});
        this.setState({width: window.innerWidth - 75})
    }

    getCompanies() {
        let resp = [];
        for (let i = 0; i < this.state.companies.length; ++i) {
            let company = this.state.companies[i];
            console.log(company)
            resp.push(
                <CompanyEmployees
                    company={company}
                    key={i}
                />
            )
        }
        return resp;
    }

    render() {
        return (
            <div>
                <NavBar selected={"employees"}/>
                <div style={{height: 'auto', position: 'absolute', top: 65, left: 75, display: 'flex', flex: 1}}>
                    <div style={{
                        overflow: 'hidden',
                        height: this.state.height,
                        width: this.state.width,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        flex: 1
                    }}>
                        <h1 style={{color: '#eee', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10%', margin: 0, width: '100%'}}>
                            Employees
                        </h1>
                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', height: '90%'}}>
                            {this.getCompanies()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmployeeOverviewBody;
